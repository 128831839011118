import {ChangeDetectionStrategy, Component} from '@angular/core';
import {LoaderService} from '../../service/loader.service';
import {debounceTime} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoaderComponent {
    public loading$: Observable<boolean>;

    constructor(private readonly loaderService: LoaderService) {
        this.loading$ = this.loaderService.loading$.pipe(debounceTime(0));
    }
}
