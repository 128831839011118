import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BaseService } from './base.service';
import { CommonModule } from '@angular/common';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { environment } from '../../environments/environment';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PermissionService } from './permission.service';
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { UrlParamsService } from './url-params.service';
import { BasicComponent } from './basic.component';
import { MetamaskService } from './metamask.service';
import {LocalStorageModule} from "./local-storage";
import {ProfileApiService} from "./services/profile.api.service";
import {ProjectApiService} from "./services/project.api.service";
import {TenantApiService} from "./services/tenant.api.service";
import {ArbitrageApiService} from "@core/services/arbitrage.api.service";
import {CoingeckoApiService} from "@core/services/coingecko.api.service";
import {NavigateService} from "@core/services/navigate.service";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        HttpClientModule,
        FlexLayoutModule,
        NzNotificationModule,
        LocalStorageModule.forRoot(),
        AuthModule.forRoot({
            domain: environment.domain,
            clientId: environment.clientID,
            authorizationParams: {
                redirect_uri: window.location.origin,
                audience: environment.audience
            },
            httpInterceptor: {
                allowedList: [
                    {
                        // Match any request that starts 'https://YOUR_DOMAIN/api/v2/' (note the asterisk)
                        uri: environment.baseUrl + '*',
                        tokenOptions: {
                            authorizationParams: {
                                audience: environment.audience
                            }
                        }
                    }
                ]
            }
        }),
    ],
    providers: [],
    declarations: [BasicComponent],
    exports: [],
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error('CoreModule is already loaded. Import it in the RclmModule only');
        }
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return {
            ngModule: CoreModule,
            providers: [
                BaseService,
                { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
                PermissionService,
                UrlParamsService,
                MetamaskService,
                ProfileApiService,
                ProjectApiService,
                TenantApiService,
                ArbitrageApiService,
                CoingeckoApiService,
                NavigateService,
            ],
        };
    }
}
