import {Component, Inject, Input} from '@angular/core';
import {NZ_MODAL_DATA, NzModalRef} from 'ng-zorro-antd/modal';
import {ConnectMetamaskModalData, NetworkItem} from './connect-metamask-modal.interface';
import {SUPPORTED_NETWORKS} from "@shared/constants/networks.const";

@Component({
    selector: 'app-connect-metamask-modal',
    templateUrl: './connect-metamask-modal.component.html',
    styleUrls: ['./connect-metamask-modal.component.less'],
})
export class ConnectMetamaskModalComponent {
    selectedNetwork: NetworkItem | null = null;
    supportedNetworks = SUPPORTED_NETWORKS;

    constructor(
        private modal: NzModalRef,
        @Inject(NZ_MODAL_DATA) public data: ConnectMetamaskModalData
    ) {
        this.selectedNetwork = data.selectedNetwork;
    }

    selectNetwork(network: NetworkItem) {
        this.modal.destroy({
            result: true,
            selectedNetwork: network,
        })
    }
}
