import {Component, OnInit} from '@angular/core';
import {NavigateService} from "@core/services/navigate.service";
import {filter, take} from "rxjs";
import {RolesEnum} from "@shared/enums/roles.enum";
import {PermissionService} from "@core/permission.service";

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.less'],
})
export class AppComponent implements OnInit {
    year = new Date().getFullYear();
    isViewer: boolean;
    constructor(
        private readonly permissions: PermissionService,
    ) {
    }

    ngOnInit() {
        console.log('created build2');
        this.permissions.isReady
            .pipe(
                filter((r) => r),
                take(1),
            )
            .subscribe(() => {
                this.isViewer = this.permissions.isInRole(RolesEnum.ViewClientDashboard);
            });
    }
}
