import {
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    EmbeddedViewRef,
    Input,
    TemplateRef,
    ViewContainerRef,
    ɵstringify as stringify,
} from '@angular/core';
import { BlockLoaderComponent } from '../../components/block-loader/block-loader.component';

export class AppLoaderContext<T = unknown> {
    // tslint:disable-next-line:no-non-null-assertion
    public $implicit: T = null!;
    // tslint:disable-next-line:no-non-null-assertion
    public appLoader: T = null!;
}

@Directive({
    selector: '[appLoader]',
})
export class LoaderDirective<T = any> {
    private context: AppLoaderContext<T> = new AppLoaderContext<T>();
    private thenTemplateRef: TemplateRef<AppLoaderContext<T>> | null = null;
    private elseTemplateRef: TemplateRef<AppLoaderContext<T>> | null = null;
    private thenViewRef: EmbeddedViewRef<AppLoaderContext<T>> | null = null;
    private elseViewRef: EmbeddedViewRef<AppLoaderContext<T>> | null = null;
    private loaderComponentRef: ComponentRef<BlockLoaderComponent> | null = null;

    constructor(
        private readonly viewContainer: ViewContainerRef,
        private readonly templateRef: TemplateRef<AppLoaderContext<T>>,
        private readonly resolver: ComponentFactoryResolver,
    ) {
        this.thenTemplateRef = templateRef;
    }

    /**
     * The Boolean expression to evaluate as the condition for showing a template.
     */
    @Input()
    set appLoader(condition: T) {
        this.context.$implicit = this.context.appLoader = condition;
        this._updateView();
    }

    /**
     * A template to show if the condition expression evaluates to true.
     */
    @Input()
    set appLoaderThen(templateRef: TemplateRef<AppLoaderContext<T>> | null) {
        assertTemplate('appLoaderThen', templateRef);
        this.thenTemplateRef = templateRef;
        this.thenViewRef = null; // clear previous view if any.
        this._updateView();
    }

    /**
     * A template to show if the condition expression evaluates to false.
     */
    @Input()
    set appLoaderElse(templateRef: TemplateRef<AppLoaderContext<T>> | null) {
        assertTemplate('appLoaderElse', templateRef);
        this.elseTemplateRef = templateRef;
        this.elseViewRef = null; // clear previous view if any.
        this._updateView();
    }

    private _updateView() {
        if (this.context.$implicit) {
            if (!this.thenViewRef) {
                this.viewContainer.clear();
                this.elseViewRef = null;
                if (this.thenTemplateRef) {
                    this.thenViewRef = this.viewContainer.createEmbeddedView(
                        this.thenTemplateRef,
                        this.context,
                    );
                    this.loaderComponentRef = null;
                }
            }
        } else {
            if (!this.elseViewRef) {
                this.viewContainer.clear();
                this.thenViewRef = null;
                if (this.elseTemplateRef) {
                    this.elseViewRef = this.viewContainer.createEmbeddedView(
                        this.elseTemplateRef,
                        this.context,
                    );
                    this.loaderComponentRef = null;
                }
                this.createComponent();
            } else {
                this.createComponent();
            }
        }
    }

    private createComponent(): void {
        if (!this.loaderComponentRef) {
            const factory = this.resolver.resolveComponentFactory(BlockLoaderComponent);
            this.loaderComponentRef = this.viewContainer.createComponent(factory);
        }
    }
}

function assertTemplate(property: string, templateRef: TemplateRef<any> | null): void {
    const isTemplateRefOrNull = !!(!templateRef || templateRef.createEmbeddedView);
    if (!isTemplateRefOrNull) {
        throw new Error(
            `${property} must be a TemplateRef, but received '${stringify(templateRef)}'.`,
        );
    }
}
