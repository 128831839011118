import {ArbitrageDexNames, DexNamesEnum} from '../enums/dex-names.enum';
import {Dexes} from "@shared/interfaces/profiles.interface";
import {NetworkNamesEnum} from "@shared/enums/networks.enum";

export const DEX_VALUES: Dexes = {
    // [DexNamesEnum.blhswap]: {
    //     dex: 'BLH',
    //     network: 'BSC',
    //     networkName: NetworkNamesEnum.bsc,
    // },
    [DexNamesEnum.quickswap]: {
        dex: 'QuickSwap',
        network: 'Polygon',
        networkName: NetworkNamesEnum.pol,
    },
    [DexNamesEnum.uniswap]: {
        dex: 'Uni',
        network: 'ETH-Mainnet',
        networkName: NetworkNamesEnum.eth,
    },
    [DexNamesEnum.pancakeswap]: {
        dex: 'Pancake V2',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum.pangolinswap]: {
        dex: 'Pangolin',
        network: 'Avalanche',
        networkName: NetworkNamesEnum.ava,
    },
    [DexNamesEnum.joeswap]: {
        dex: 'Joe',
        network: 'Avalanche',
        networkName: NetworkNamesEnum.ava,
    },
    [DexNamesEnum.apeswap]: {
        dex: 'Ape',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum.dmmswap]: {
        dex: 'Kyber/DMM',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum.spookyswap]: {
        dex: 'Spooky',
        network: 'Fantom',
        networkName: NetworkNamesEnum.ftm,
    },
    [DexNamesEnum.sushiswap_eth]: {
        dex: 'Sushi',
        network: 'ETH-Mainnet',
        networkName: NetworkNamesEnum.eth,
    },
    [DexNamesEnum.sushiswap_bsc]: {
        dex: 'Sushi',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum.sushiswap_pol]: {
        dex: 'Sushi',
        network: 'Polygon',
        networkName: NetworkNamesEnum.pol,
    },
    [DexNamesEnum.sushiswap_heco]: {
        dex: 'Sushi',
        network: 'HECO',
        networkName: NetworkNamesEnum.heco,
    },
    [DexNamesEnum.sushiswap_harmony]: {
        dex: 'Sushi',
        network: 'Harmony',
        networkName: NetworkNamesEnum.harmony,
    },
    [DexNamesEnum.biswap]: {
        dex: 'Biswap',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum.mdexswap]: {
        dex: 'MDEX',
        network: 'HECO',
        networkName: NetworkNamesEnum.heco,
    },
    [DexNamesEnum.knightswap]: {
        dex: 'Knightswap',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    // [DexNamesEnum['bsc-testswap']]: {
    //     dex: 'BSC Test',
    //     network: 'BSC-Testnet',
    //     networkName: NetworkNamesEnum["bsc-tst"],
    // },
    // [DexNamesEnum['goerli-testswap']]: {
    //     dex: 'Goerli',
    //     network: 'Goerli Testnet',
    //     networkName: NetworkNamesEnum.goerli,
    // },
    [DexNamesEnum['uniswap_v3']]: {
        dex: 'UniV3',
        network: 'ETH-Mainnet',
        networkName: NetworkNamesEnum.eth,
    },
    [DexNamesEnum['uniswap_v3_pol']]: {
        dex: 'UniV3',
        network: 'Polygon',
        networkName: NetworkNamesEnum.pol,
    },
    [DexNamesEnum['uniswap_v3_arb']]: {
        dex: 'UniV3',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum['uniswap_v3_celo']]: {
        dex: 'UniV3',
        network: 'Celo',
        networkName: NetworkNamesEnum.celo,
    },
    [DexNamesEnum['uniswap_v3_base']]: {
        dex: 'UniV3',
        network: 'Base',
        networkName: NetworkNamesEnum.base,
    },
    [DexNamesEnum['uniswap_v3_opt']]: {
        dex: 'UniV3',
        network: 'Optimism',
        networkName: NetworkNamesEnum.optimism,
    },
    [DexNamesEnum.oreoswap]: {
        dex: 'OreoSwap',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.zyberswap]: {
        dex: 'Zyberswap',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    // [DexNamesEnum.thena]: {
    //     dex: 'Thena',
    //     network: 'BSC',
    //     networkName: NetworkNamesEnum.bsc,
    // },
    [DexNamesEnum.alienswap]: {
        dex: 'Alienswap',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.camelot]: {
        dex: 'Camelot',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.zyberswap_v3]: {
        dex: 'Zyberswap V3',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.pancakeswap_v3]: {
        dex: 'Pancake V3',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum.shibaswap]: {
        dex: 'Shibaswap',
        network: 'Ethereum',
        networkName: NetworkNamesEnum.eth,
    },
    [DexNamesEnum['uniswap_v3_bsc']]: {
        dex: 'UniV3',
        network: 'BSC',
        networkName: NetworkNamesEnum.bsc,
    },
    [DexNamesEnum['quickswap_v3']]: {
        dex: 'QuickSwap v3',
        network: 'Polygon',
        networkName: NetworkNamesEnum.pol,
    },
    [DexNamesEnum.dexSwap]: {
        dex: 'dexSWAP',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.joeswap_v2_ava]: {
        dex: 'Trader Joe V2',
        network: 'Avalanche',
        networkName: NetworkNamesEnum.ava,
    },
    [DexNamesEnum.joeswap_v2_arb]: {
        dex: 'Trader Joe V2',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum
    },
    [DexNamesEnum.pancakeswap_zksync]: {
        dex: 'Pancake V2',
        network: 'ZKSync Era',
        networkName: NetworkNamesEnum.zksync
    },
    [DexNamesEnum.pancakeswap_v3_zksync]: {
        dex: 'Pancake V3',
        network: 'ZKSync Era',
        networkName: NetworkNamesEnum.zksync
    },
    [DexNamesEnum.synthswap]: {
        dex: 'Synthswap',
        network: 'Base',
        networkName: NetworkNamesEnum.base
    },
    [DexNamesEnum.synthswap_v3]: {
        dex: 'Synthswap V3',
        network: 'Base',
        networkName: NetworkNamesEnum.base
    },
    [DexNamesEnum.camelot_v3]: {
        dex: 'Camelot V3',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum
    },
    [DexNamesEnum.sushiswap_arb]: {
        dex: 'Sushi',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.sushiswap_v3_arb]: {
        dex: 'Sushi V3',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.balancer_eth]: {
        dex: 'Balancer',
        network: 'Ethereum',
        networkName: NetworkNamesEnum.eth,
    },
    [DexNamesEnum.balancer_pol]: {
        dex: 'Balancer',
        network: 'Polygon',
        networkName: NetworkNamesEnum.pol,
    },
    // [DexNamesEnum.sushiswap_v3_core]: {
    //     dex: 'Sushi V3 Core',
    //     network: 'Core',
    //     networkName: NetworkNamesEnum.core,
    // },
    [DexNamesEnum.raydium]: {
        dex: 'Raydium',
        network: 'Solana',
        networkName: NetworkNamesEnum.solana,
    },
    [DexNamesEnum.raydium_v3]: {
        dex: 'Raydium V3',
        network: 'Solana',
        networkName: NetworkNamesEnum.solana,
    },
    [DexNamesEnum.sushiswap_base]: {
        dex: 'Sushi',
        network: 'Base',
        networkName: NetworkNamesEnum.base,
    },
    [DexNamesEnum.thruster_v2]: {
        dex: 'Thruster V2',
        network: 'Blast',
        networkName: NetworkNamesEnum.blast,
    },
    [DexNamesEnum.thruster_v3]: {
        dex: 'Thruster V3',
        network: 'Blast',
        networkName: NetworkNamesEnum.blast,
    },
    [DexNamesEnum.cyberblast_v2]: {
        dex: 'Cyberblast V2',
        network: 'Blast',
        networkName: NetworkNamesEnum.blast,
    },
    [DexNamesEnum.cyberblast_v3]: {
        dex: 'Cyberblast V3',
        network: 'Blast',
        networkName: NetworkNamesEnum.blast,
    },
    [DexNamesEnum.uniswap_base]: {
        dex: 'Uni',
        network: 'Base',
        networkName: NetworkNamesEnum.base,
    },
    [DexNamesEnum.alien_base]: {
        dex: 'Alien',
        network: 'Base',
        networkName: NetworkNamesEnum.base,
    },
    [DexNamesEnum.alien_v3_base]: {
        dex: 'Alien V3',
        network: 'Base',
        networkName: NetworkNamesEnum.base,
    },
    // [DexNamesEnum.saucerswap_hedera]: {
    //     dex: 'Saucerswap',
    //     network: 'Hedera',
    //     networkName: NetworkNamesEnum.hedera,
    // },
    // [DexNamesEnum.saucerswap_v3_hedera]: {
    //     dex: 'Saucerswap V3',
    //     network: 'Hedera',
    //     networkName: NetworkNamesEnum.hedera,
    // },
    [DexNamesEnum.balancer_arb]: {
        dex: 'Balancer',
        network: 'Arbitrum',
        networkName: NetworkNamesEnum.arbitrum,
    },
    [DexNamesEnum.aerodrome_base]: {
        dex: 'Aerodrome',
        network: 'Base',
        networkName: NetworkNamesEnum.base,
    },
    [DexNamesEnum.dedust]: {
        dex: 'DeDust',
        network: 'TON',
        networkName: NetworkNamesEnum.ton,
    },
    [DexNamesEnum.sushiswap_haqq]: {
        dex: 'Sushi',
        network: 'HAQQ',
        networkName: NetworkNamesEnum.haqq,
    },
    [DexNamesEnum.sushiswap_v3_haqq]: {
        dex: 'Sushi V3',
        network: 'HAQQ',
        networkName: NetworkNamesEnum.haqq,
    },
    [DexNamesEnum.curve_eth]: {
        dex: 'Curve',
        network: 'Ethereum',
        networkName: NetworkNamesEnum.eth,
    },
    [DexNamesEnum.curve_pol]: {
        dex: 'Curve',
        network: 'Polygon',
        networkName: NetworkNamesEnum.pol,
    },
    [DexNamesEnum.vtru]: {
        dex: 'VTRU',
        network: 'Vitruveo',
        networkName: NetworkNamesEnum.vtru,
    },
    [DexNamesEnum.taffy]: {
        dex: 'Taffy',
        network: 'Saakuru',
        networkName: NetworkNamesEnum.saakuru,
    },
    [DexNamesEnum.meteora]: {
        dex: 'Meteora',
        network: 'Solana',
        networkName: NetworkNamesEnum.solana,
    },
    [DexNamesEnum.hypecoin]: {
        dex: 'Hypecoin',
        network: 'TON',
        networkName: NetworkNamesEnum.ton
    },
    [DexNamesEnum.sunswap]: {
        dex: 'SunSwap',
        network: 'Tron',
        networkName: NetworkNamesEnum.tron,
    },
    [DexNamesEnum.stonfi]: {
        dex: 'StonFi',
        network: 'TON',
        networkName: NetworkNamesEnum.ton,
    },
    [DexNamesEnum.sunpump]: {
        dex: 'SunPump',
        network: 'Tron',
        networkName: NetworkNamesEnum.tron,
    },
    [DexNamesEnum.kim]: {
        dex: 'Kim',
        network: 'Mode',
        networkName: NetworkNamesEnum.mode,
    },
    [DexNamesEnum.uniswap_v3_mantle]: {
        dex: 'UniV3',
        network: 'Mantle',
        networkName: NetworkNamesEnum.mantle,
    },
    [DexNamesEnum.cetus_sui]: {
        dex: 'Cetus',
        network: 'Sui',
        networkName: NetworkNamesEnum.sui,
    },
    [DexNamesEnum.turbos_sui]: {
        dex: 'Turbos',
        network: 'Sui',
        networkName: NetworkNamesEnum.sui,
    },
    [DexNamesEnum.kaiaswap]: {
        dex: 'KaiaSwap',
        network: 'Kaia',
        networkName: NetworkNamesEnum.kaia,
    },
    [DexNamesEnum.klayswap_v2]: {
        dex: 'KlaySwap',
        network: 'Kaia',
        networkName: NetworkNamesEnum.kaia,
    }
}

export const ARBITRAGE_DEX_VALUES = {
    [ArbitrageDexNames.uniswapV2]: {
        name: DexNamesEnum.uniswap,
        image: 'assets/icons/dexes/uniswap_v2.svg',
    },
    [ArbitrageDexNames.uniswapV3]: {
        name: DexNamesEnum.uniswap_v3,
        image: 'assets/icons/dexes/uniswap_v3.svg',
    },
    [ArbitrageDexNames.sushiswapV2]: {
        name: DexNamesEnum.sushiswap_bsc,
        image: 'assets/icons/dexes/uniswap_v2.svg',
    },
    [ArbitrageDexNames.sushiswapV3]: {
        name: DexNamesEnum.sushiswap_eth,
        image: 'assets/icons/dexes/sushiswap_v3.svg',
    },
    [ArbitrageDexNames.pancakeswapV2]: {
        name: DexNamesEnum.pancakeswap,
        image: 'assets/icons/dexes/pancakeswap_v2.svg',
    },
    [ArbitrageDexNames.pancakeswapV3]: {
        name: DexNamesEnum.pancakeswap_v3,
        image: 'assets/icons/dexes/pancakeswap_v3.svg',
    }
}



