import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BasePermission } from './base-permission';
import { PermissionService } from '../../../core/permission.service';

/**
 * Hides the content if user matches permissions.
 * See syntax in BasePermission class
 */
@Directive({ selector: '[appHideFor]' })
export class HideForDirective extends BasePermission {
    constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        permissionService: PermissionService,
    ) {
        super(templateRef, viewContainer, permissionService);
    }

    @Input() set appHideFor(input: any) {
        const denyPermission = this.denyPermission(input);
        this.toggleVisibility(!denyPermission);
    }
}
