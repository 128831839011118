import {CoingeckoDexNamesEnum, DexNamesEnum} from "@shared/enums/dex-names.enum";
import {CexNamesEnum, NetworkNamesEnum} from "@shared/enums/networks.enum";

export enum CoingeckoNetworks {
    Ethereum = 'ethereum',
    Avalanche = 'avalanche',
    BSC = 'binance-smart-chain',
    Harmony = 'harmony-shard-0',
    Polygon = 'polygon-pos',
    Fantom = 'fantom',
    HECO = 'huobi-token',
    Optimism = 'optimistic-ethereum',
    Arbitrum = 'arbitrum-one',
    Celo = 'celo',
    Solana = 'solana',
    Base = 'base',
}

export enum CoingeckoCexes {
    'gateio' = 'gate',
    'binance' = 'binance',
    'mexc' = 'mxc',
    'huobipro' = 'huobi',
    'kucoin' = 'kucoin',
    'whitebit' = 'whitebit',
    'okx' = 'okex',
}

export const COINGECKO_DEX = [
    {
        dex: DexNamesEnum.quickswap,
        coingeckoDex: CoingeckoDexNamesEnum.quickswap,
        network: NetworkNamesEnum.pol,
        platformNetworkName: CoingeckoNetworks.Polygon,
    },
    {
        dex: DexNamesEnum.uniswap,
        coingeckoDex: CoingeckoDexNamesEnum.uniswap,
        network: NetworkNamesEnum.eth,
        platformNetworkName: CoingeckoNetworks.Ethereum,
    },
    {
        dex: DexNamesEnum.uniswap_v3,
        coingeckoDex: CoingeckoDexNamesEnum.uniswap_v3,
        network: NetworkNamesEnum.eth,
        platformNetworkName: CoingeckoNetworks.Ethereum,
    },
    {
        dex: DexNamesEnum.uniswap_v3_pol,
        coingeckoDex: CoingeckoDexNamesEnum.uniswapV3Pol,
        network: NetworkNamesEnum.pol,
        platformNetworkName: CoingeckoNetworks.Polygon,
    },
    {
        dex: DexNamesEnum.uniswap_v3_opt,
        coingeckoDex: CoingeckoDexNamesEnum.uniswapV3Opt,
        network: NetworkNamesEnum.optimism,
        platformNetworkName: CoingeckoNetworks.Optimism,
    },
    {
        dex: DexNamesEnum.uniswap_v3_base,
        coingeckoDex: CoingeckoDexNamesEnum.uniswapV3Opt,
        network: NetworkNamesEnum.optimism,
        platformNetworkName: CoingeckoNetworks.Optimism,
    },
    {
        dex: DexNamesEnum.uniswap_v3_celo,
        coingeckoDex: CoingeckoDexNamesEnum.uniswapV3Opt,
        network: NetworkNamesEnum.celo,
        platformNetworkName: CoingeckoNetworks.Celo,
    },
    {
        dex: DexNamesEnum.uniswap_v3_arb,
        coingeckoDex: CoingeckoDexNamesEnum.uniswapV3Arb,
        network: NetworkNamesEnum.arbitrum,
        platformNetworkName: CoingeckoNetworks.Arbitrum,
    },
    {
        dex: DexNamesEnum.pancakeswap,
        coingeckoDex: CoingeckoDexNamesEnum.pancakeswap,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    {
        dex: DexNamesEnum.pancakeswap_v3,
        coingeckoDex: CoingeckoDexNamesEnum.pancakeswap_v3,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    {
        dex: DexNamesEnum.joeswap,
        coingeckoDex: CoingeckoDexNamesEnum.joeswap,
        network: NetworkNamesEnum.ava,
        platformNetworkName: CoingeckoNetworks.Avalanche,
    },
    // {
    //     dex: DexNamesEnum.joeswap,
    //     coingeckoDex: CoingeckoDexNamesEnum.joeV2Arb,
    //     network: NetworkNamesEnum.arbitrum,
    //     platformNetworkName: CoingeckoNetworks.Arbitrum,
    // },
    // {
    //     dex: DexNamesEnum.joeswap,
    //     coingeckoDex: CoingeckoDexNamesEnum.joeV2Ava,
    //     network: NetworkNamesEnum.ava,
    //     platformNetworkName: CoingeckoNetworks.Avalanche,
    // },
    // {
    //     dex: DexNamesEnum.joeswap,
    //     coingeckoDex: CoingeckoDexNamesEnum.joeV2Bsc,
    //     network: NetworkNamesEnum.bsc,
    //     platformNetworkName: CoingeckoNetworks.BSC,
    // },
    {
        dex: DexNamesEnum.spookyswap,
        coingeckoDex: CoingeckoDexNamesEnum.spookyswap,
        network: NetworkNamesEnum.ftm,
        platformNetworkName: CoingeckoNetworks.Fantom,
    },
    {
        dex: DexNamesEnum.babyswap,
        coingeckoDex: CoingeckoDexNamesEnum.babyswap,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    {
        dex: DexNamesEnum.sushiswap_eth,
        coingeckoDex: CoingeckoDexNamesEnum.sushiswap,
        network: NetworkNamesEnum.eth,
        platformNetworkName: CoingeckoNetworks.Ethereum,
    },
    {
        dex: DexNamesEnum.sushiswap_bsc,
        coingeckoDex: CoingeckoDexNamesEnum.sushiswapBsc,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    {
        dex: DexNamesEnum.sushiswap_harmony,
        coingeckoDex: CoingeckoDexNamesEnum.sushiswapHarmony,
        network: NetworkNamesEnum.harmony,
        platformNetworkName: CoingeckoNetworks.Harmony,
    },
    {
        dex: DexNamesEnum.sushiswap_pol,
        coingeckoDex: CoingeckoDexNamesEnum.sushiswapPol,
        network: NetworkNamesEnum.pol,
        platformNetworkName: CoingeckoNetworks.Polygon,
    },
    {
        dex: DexNamesEnum.biswap,
        coingeckoDex: CoingeckoDexNamesEnum.biswap,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    {
        dex: DexNamesEnum.mdexswap,
        coingeckoDex: CoingeckoDexNamesEnum.mdexswap,
        network: NetworkNamesEnum.heco,
        platformNetworkName: CoingeckoNetworks.HECO,
    },
    {
        dex: DexNamesEnum.pangolinswap,
        coingeckoDex: CoingeckoDexNamesEnum.pangolinswap,
        network: NetworkNamesEnum.ava,
        platformNetworkName: CoingeckoNetworks.Avalanche,
    },
    {
        dex: DexNamesEnum.apeswap,
        coingeckoDex: CoingeckoDexNamesEnum.apeswapBsc,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    // {
    //     dex: DexNamesEnum.apeswap,
    //     coingeckoDex: CoingeckoDexNamesEnum.apeswapPol,
    //     network: NetworkNamesEnum.pol,
    //     platformNetworkName: CoingeckoNetworks.Polygon,
    // },
    {
        dex: DexNamesEnum.knightswap,
        coingeckoDex: CoingeckoDexNamesEnum.knightswap,
        network: NetworkNamesEnum.bsc,
        platformNetworkName: CoingeckoNetworks.BSC,
    },
    {
        dex: DexNamesEnum.oreoswap,
        coingeckoDex: CoingeckoDexNamesEnum.oreoswap,
        network: NetworkNamesEnum.arbitrum,
        platformNetworkName: CoingeckoNetworks.Arbitrum,
    },
    {
        dex: DexNamesEnum.zyberswap,
        coingeckoDex: CoingeckoDexNamesEnum.zyberswap,
        network: NetworkNamesEnum.arbitrum,
        platformNetworkName: CoingeckoNetworks.Arbitrum,
    },
    {
        dex: DexNamesEnum.alien_v3_base,
        coingeckoDex: CoingeckoDexNamesEnum.alienBaseV3,
        network: NetworkNamesEnum.base,
        platformNetworkName: CoingeckoNetworks.Base,
    },
]

export const COINGECKO_CEX = [
    {
        cex: CexNamesEnum.gateio,
        coingeckoCex: CoingeckoCexes.gateio,
    },
    {
        cex: CexNamesEnum.binance,
        coingeckoCex: CoingeckoCexes.binance,
    },
    {
        cex: CexNamesEnum.mexc,
        coingeckoCex: CoingeckoCexes.mexc,
    },
    {
        cex: CexNamesEnum.huobipro,
        coingeckoCex: CoingeckoCexes.huobipro,
    },
    {
        cex: CexNamesEnum.kucoin,
        coingeckoCex: CoingeckoCexes.kucoin,
    },
    {
        cex: CexNamesEnum.whitebit,
        coingeckoCex: CoingeckoCexes.whitebit,
    },
    {
        cex: CexNamesEnum.okx,
        coingeckoCex: CoingeckoCexes.okx,
    },
]
