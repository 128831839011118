import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisableForDirective } from './disable-for.directive';
import { EnableForDirective } from './enable-for.directive';
import { HideForDirective } from './hide-for.directive';
import { ShowForDirective } from './show-for.directive';
import {ShowPermissionsForDirective} from "@shared/directives/permissions/show-for-permission.directive";

@NgModule({
    declarations: [
        DisableForDirective,
        EnableForDirective,
        HideForDirective,
        ShowForDirective,
        ShowPermissionsForDirective,
    ],
    exports: [
        DisableForDirective,
        EnableForDirective,
        HideForDirective,
        ShowForDirective,
        ShowPermissionsForDirective,
    ],
    imports: [
        CommonModule
    ]
})

export class PermissionsModule {
}
