import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {BasePermission} from './base-permission';
import {PermissionService} from '@core/permission.service';

/**
 * Shows the content if user matches permissions.
 * See syntax in BasePermission class
 */
@Directive({ selector: '[appShowFor]' })
export class ShowForDirective extends BasePermission {

    @Input() set appShowFor(input: any) {
        const hasPermission = this.hasPermission(input);
        this.toggleVisibility(hasPermission);
    }

    constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        permissionService: PermissionService,
    ) {
        super(templateRef, viewContainer, permissionService);
    }
}
