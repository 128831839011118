import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BasePermission } from './base-permission';
import { PermissionService } from '../../../core/permission.service';

/**
 * Disables the content interraction if user matches permissions.
 * See syntax in BasePermission class
 */
@Directive({ selector: '[appDisableFor]' })
export class DisableForDirective extends BasePermission {
    constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        permissionService: PermissionService,
    ) {
        super(templateRef, viewContainer, permissionService);
    }

    @Input() set appDisableFor(input: any) {
        const denyPermission = this.denyPermission(input);
        this.toggleVisibility(true);
        this.toggleEnabled(!denyPermission);
    }
}
