<nz-header *ngIf="permissions.isReady | async"
           class="header"
           fxLayout="row"
           fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
        <div *ngIf="enableLogo" class="logo" [appBackgroundImage]="logoUrl" (click)="onLogoClick()"></div>
    </div>
    <div *ngIf="isAuth" fxLayout="row" fxLayoutAlign="center center">
        <div class="button-box" fxFlexFill fxLayout="row" fxLayoutAlign="end center">
            <div *appShowFor="rolesEnum.constable">Hello mr.Constable!</div>
            <w3m-button *ngIf="enableLogo" class="wallet-connect-btn" ></w3m-button>
            <button nz-button nzType="text" (click)="logout()">Log Out</button>
        </div>
    </div>
</nz-header>
