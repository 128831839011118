import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appBackgroundImage]',
    /* tslint:disable */
    host: {
        '[style.background-image]': '"url("+ appBackgroundImage + ")"',
        '[style.background-size]': 'backgroundSize || "cover"',
        '[style.background-repeat]': '"no-repeat"',
        '[style.background-position]': '"center center"'
    }
    /* tslint:enable */
})
export class BackgroundImageDirective {
    @Input() appBackgroundImage: any;
    @Input() backgroundSize: any;

    constructor() { }
}
