export function getCurrentDate(date?: number | string | Date | null): Date {
    return date ? new Date(date) : new Date();
}

export function getCurrentDateEnd(date?: number | string | Date): Date {
    const d = date ? new Date(date) : new Date();
    return new Date(d.setHours(23, 59, 59));
}

export function diffDaysBetweenDates(date1: number | string | Date, date2: number | string | Date): number {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return Math.round((d2.getTime() - d1.getTime()) / (1000 * 3600 * 24));
}

export function getPreviousDate(date?: number | string | Date | null, days: number = 7): Date {
    const day = getCurrentDate(date);
    return getCurrentDate(day.valueOf() - days * 24 * 60 * 60 * 1000);
}


export function getISODates(dates: (string | Date)[]): string[] {
    return dates.map(date => new Date(date).toISOString());
}

export function dateToUTC(date: number | string | Date): Date {
    const d = new Date(date);
    const offset = d.getTimezoneOffset();
    return new Date(d.setMinutes(d.getMinutes() + offset));
}

export function UTCtoISO(date: number | string | Date): string {
    const d = new Date(date);
    d.setMinutes(0);
    const offset = d.getTimezoneOffset();
    return new Date(d.setMinutes(d.getMinutes() - offset)).toISOString();
}

export function timeToDate(date: number | string | Date): string {
    const today = new Date();
    const d = new Date(date);
    const diff = (today.getTime() - d.getTime()) / (1000 * 3600);
    if (diff < 1) {
        return 'Recently';
    }
    if (!Math.trunc(diff / 24)) {
        return `${Math.trunc(diff)} hour${Math.trunc(diff) === 1 ? '' : 's'} ago`;
    }
    return `${Math.trunc(diff / 24)} day${Math.trunc(diff / 24) === 1 ? '' : 's'} ago`;
}
