import {Injectable} from '@angular/core';
import {AuthService} from '@auth0/auth0-angular';
import {AuthMetaData} from './auth.const';
import {RolesEnum} from '../shared/enums/roles.enum';
import {BehaviorSubject, shareReplay} from 'rxjs';
import {Router} from "@angular/router";
import {NavigateService} from "@core/services/navigate.service";
import {jwtDecode} from "jwt-decode";
import {PermissionsEnum} from "@shared/enums/permissions.enum";

@Injectable()
export class PermissionService {
    private userRoles: RolesEnum[];
    private hasRoles = new BehaviorSubject(false);
    private hasPermissions = new BehaviorSubject(false);
    private currentTenant: number[];
    private currentEmail: string | undefined;
    private availableProjects: number[];
    private logo: string;
    private arbitrageTenant: number[];
    private arbitrageAvailableProjects: number[];
    private permissionsList: PermissionsEnum[];
    private projectWithdraws: number[];
    private viewProfilesList: number[];

    constructor(
        private auth: AuthService,
        private readonly router: Router,
        private readonly navigateService: NavigateService,
    ) {}

    initPermissions() {
        this.auth.idTokenClaims$.subscribe((claims) => {
            if (claims && claims[AuthMetaData.auth_roles]) {
                this.userRoles = claims[AuthMetaData.auth_roles];
                this.currentTenant = claims[AuthMetaData.auth_tenant];
                this.currentEmail = claims[AuthMetaData.email];
                this.availableProjects = claims[AuthMetaData.availableProjects];
                this.logo = claims[AuthMetaData.logoUrl];
                this.arbitrageTenant = claims[AuthMetaData.arbitrageTenant];
                this.arbitrageAvailableProjects = claims[AuthMetaData.arbitrageProjects];
                this.projectWithdraws = claims[AuthMetaData.withdrawProjects];
                this.viewProfilesList = claims[AuthMetaData.viewProfiles];
            }
            this.hasRoles.next(true);
        });

        this.auth.getAccessTokenSilently().subscribe(val => {
            this.permissionsList = (jwtDecode(val) as any)?.permissions;
            this.hasPermissions.next(true);
        });
    }

    get isReady() {
        return this.hasRoles.asObservable().pipe(shareReplay(1));
    }

    get isPermissionsReady() {
        return this.hasPermissions.asObservable().pipe(shareReplay(1));
    }

    get roles() {
        return this.userRoles;
    }

    get tenantId() {
        return this.currentTenant;
    }

    get userEmail() {
        return this.currentEmail;
    }

    get userProjects() {
        return this.availableProjects;
    }

    get permissions() {
        return this.permissionsList;
    }

    get logoUrl() {
        return this.logo;
    }

    get arbitrageTenantId() {
        return this.arbitrageTenant;
    }

    get arbitrageProjects() {
        return this.arbitrageAvailableProjects;
    }

    isInRole(role: RolesEnum): boolean {
        return this.userRoles.includes(role);
    }

    isInRoles(roles: RolesEnum[]): boolean {
        const res = this.userRoles.filter((r) => roles.includes(r));
        return !!res.length;
    }

    isCanWithdraw(projectId: number) {
        return !this.projectWithdraws?.length || this.projectWithdraws.includes(projectId);
    }

    get viewProfiles() {
        return this.viewProfilesList;
    }

    navigateByPermissions(): void {
        if (this.isInRole(RolesEnum.ViewClientDashboard)) {
            this.viewProfilesList?.length ?
            this.navigateService.navigateToProfileView(this.viewProfilesList[0]) :
            this.navigateService.navigateToNotFound();
        } else if (this.isInRole(RolesEnum.TenantAdmin)) {
            this.navigateService.navigateToTenants();
        } else {
            if (!!this.tenantId && this.tenantId.length) {
                if (this.userProjects.length > 1 || !this.userProjects.length || this.isInRole(RolesEnum.ProjectAdmin)) {
                    this.navigateService.navigateToTenant(this.tenantId[0]);
                } else {
                    this.navigateService.navigateToProject(this.userProjects[0], this.tenantId[0]);
                }
            } else if (this.arbitrageTenantId && this.arbitrageTenantId.length) {
                if (this.arbitrageProjects.length > 1 || !this.arbitrageProjects.length || this.isInRole(RolesEnum.ArbProjectAdmin)) {
                    this.navigateService.navigateToArbTenant(this.arbitrageTenantId[0]);
                } else {
                    this.navigateService.navigateToArbProject(this.arbitrageProjects[0], this.arbitrageTenantId[0]);
                }
            }
        }
    }
}
