
<nz-layout>
    <app-loader></app-loader>
    <app-header></app-header>
    <nz-content class="main-content">
<!--        <app-navigation-menu *ngIf="!isViewer" class="main-content_menu"></app-navigation-menu>-->
        <router-outlet></router-outlet>
    </nz-content>
    <nz-footer  class="footer">
<!--        <div *ngIf="!isViewer" fxLayout="row" fxLayoutAlign="center center">From Ukraine 🇺🇦 with ❤️</div>-->
    </nz-footer>
</nz-layout>
