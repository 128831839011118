import {CexNamesEnum, NetworkChainIdEnum, NetworkNamesEnum} from '../enums/networks.enum';
import {CexItem, NetworkItem} from "@shared/modals/connect-metamask-modal/connect-metamask-modal.interface";
import {CoingeckoNetworks} from './coingecko-info';

export const NETWORKS_LIST = {
    [NetworkNamesEnum.eth]: 'Ethereum (ETH)',
    [NetworkNamesEnum.bsc]: 'Binance Smart Chain (BNB)',
    [NetworkNamesEnum.heco]: 'HECO (HT)',
    // [NetworkNamesEnum.ftm]: 'Fantom (FTM)',
    [NetworkNamesEnum.ava]: 'Avalanche (Avax)',
    [NetworkNamesEnum.pol]: 'Polygon (MATIC)',
    // [NetworkNamesEnum.harmony]: 'Harmony (ONE)',
    [NetworkNamesEnum.optimism]: 'Optimism (ETH)',
    [NetworkNamesEnum.arbitrum]: 'Arbitrum (ETH)',
    [NetworkNamesEnum.zksync]: 'ZKSync Era (ETH)',
    [NetworkNamesEnum.base]: 'Base (ETH)',
    // [NetworkNamesEnum.core]: 'Core (CORE)',
    //[NetworkNamesEnum.celo]: 'Celo (CELO)',
    // [NetworkNamesEnum['bsc-tst']]: 'Testnet - Binance Chain (tBNB)',
    // [NetworkNamesEnum.ropsten]: 'TESTNET Ropsten (RopstenETH)',
    // [NetworkNamesEnum.goerli]: 'Goerli Testnet (GoerliETH)',
    [NetworkNamesEnum.solana]: 'Solana (SOL)',
    [NetworkNamesEnum.blast]: 'Blast (ETH)',
    // [NetworkNamesEnum.hedera]: 'Hedera (HBAR)',
    [NetworkNamesEnum.ton]: 'TON (TON)',
    [NetworkNamesEnum.haqq]: 'HAQQ (ISLM)',
    [NetworkNamesEnum.vtru]: 'Vitruveo (VTRU)',
    [NetworkNamesEnum.saakuru]: 'Saakuru (OAS)',
    [NetworkNamesEnum.tron]: 'Tron (TRX)',
    [NetworkNamesEnum.mode]: 'Mode (ETH)',
    [NetworkNamesEnum.mantle]: 'Mantle (MNT)',
    [NetworkNamesEnum.sui]: 'Sui (SUI)',
    [NetworkNamesEnum.kaia]: 'Kaia (KLAY)',
}

export enum NetworksAssets {
    'ethereum' = 'Ethereum',
    'binance-smart-chain' = 'BSC',
    'fantom' = 'Fantom',
    'avalanche' = 'Avalanche',
    'harmony-shard-0' = 'Harmony',
    'polygon-pos' = 'Polygon'
}

export const SUPPORTED_NETWORKS: NetworkItem[] = [
    // TODO ADD NATIVE ADDRESSES
    {
        name: 'Ethereum',
        networkAlias: 'ETH',
        iconName: 'eth',
        networkColor: '#5170AD',
        networkFontColor: '#5170AD',
        network: NetworkNamesEnum.eth,
        chainId: NetworkChainIdEnum.eth,
        coingeckoId: CoingeckoNetworks.Ethereum,
    },
    {
        name: 'Avalanche',
        networkAlias: 'AVAX',
        iconName: 'ava',
        networkColor: '#EF4444',
        networkFontColor: '#EF4444',
        network: NetworkNamesEnum.ava,
        chainId: NetworkChainIdEnum.ava,
        coingeckoId: CoingeckoNetworks.Avalanche,
    },
    {
        name: 'BSC Chain',
        networkAlias: 'BNB',
        iconName: 'bsc',
        networkColor: '#292524',
        networkFontColor: '#FFCC00',
        network: NetworkNamesEnum.bsc,
        chainId: NetworkChainIdEnum.bsc,
        coingeckoId: CoingeckoNetworks.BSC,
    },
    // {
    //     name: 'Harmony',
    //     networkAlias: 'ONE',
    //     iconName: 'harmony',
    //     networkColor: '#06B6D4',
    //     networkFontColor: '#06B6D4',
    //     network: NetworkNamesEnum.harmony,
    //     chainId: NetworkChainIdEnum.harmony,
    //     platformId: CoingeckoNetworks.Harmony,
    // },
    {
        name: 'Polygon',
        networkAlias: 'MATIC',
        iconName: 'pol',
        networkColor: '#A855F7',
        networkFontColor: '#A855F7',
        network: NetworkNamesEnum.pol,
        chainId: NetworkChainIdEnum.pol,
        coingeckoId: CoingeckoNetworks.Polygon,
    },
    // {
    //     name: 'Fantom',
    //     networkAlias: 'FTM',
    //     iconName: 'ftm',
    //     networkColor: '#3B82F6',
    //     networkFontColor: '#3B82F6',
    //     network: NetworkNamesEnum.ftm,
    //     chainId: NetworkChainIdEnum.ftm,
    //     platformId: CoingeckoNetworks.Fantom,
    // },
    {
        name: 'HECO',
        networkAlias: 'HT',
        iconName: 'heco',
        networkColor: '#020a12',
        networkFontColor: '#aefb19',
        network: NetworkNamesEnum.heco,
        chainId: NetworkChainIdEnum.heco,
        coingeckoId: CoingeckoNetworks.HECO,
    },
    {
        name: 'Optimism',
        networkAlias: 'ETH',
        iconName: 'optimism',
        networkColor: '#5170AD',
        networkFontColor: '#5170AD',
        network: NetworkNamesEnum.optimism,
        chainId: NetworkChainIdEnum.optimism,
        coingeckoId: CoingeckoNetworks.Optimism,
    },
    {
        name: 'Arbitrum',
        networkAlias: 'ETH',
        iconName: 'arbitrum',
        networkColor: '#5170AD',
        networkFontColor: '#5170AD',
        network: NetworkNamesEnum.arbitrum,
        chainId: NetworkChainIdEnum.arbitrum,
        coingeckoId: CoingeckoNetworks.Arbitrum,
    },
    {
        name: 'ZKSync Era',
        networkAlias: 'ETH',
        iconName: 'zksync-era',
        networkColor: '#fe8f2d',
        networkFontColor: '#fe8f2d',
        network: NetworkNamesEnum.zksync,
        chainId: NetworkChainIdEnum.zksync,
    },
    {
        name: 'Base',
        networkAlias: 'ETH',
        iconName: 'base',
        networkColor: '#0052FF',
        networkFontColor: '#000',
        network: NetworkNamesEnum.base,
        chainId: NetworkChainIdEnum.base,
    },
    /*{
        name: 'Celo',
        networkAlias: 'CELO',
        iconName: 'celo',
        networkColor: '#fcfe00',
        networkFontColor: '#000',
        network: NetworkNamesEnum.celo,
        chainId: NetworkChainIdEnum.celo,
    },*/
    // {
    //     name: 'BSC Testnet',
    //     networkAlias: 'tBNB',
    //     iconName: 'bsc',
    //     networkColor: '#292524',
    //     networkFontColor: '#FFCC00',
    //     network: NetworkNamesEnum['bsc-tst'],
    //     chainId: NetworkChainIdEnum['bsc-tst'],
    // },
    // {
    //     name: 'Goerli',
    //     networkAlias: 'GoerliETH',
    //     iconName: 'eth',
    //     networkColor: '#5170AD',
    //     networkFontColor: '#5170AD',
    //     network: NetworkNamesEnum.goerli,
    //     chainId: NetworkChainIdEnum.goerli,
    // },
    // {
    //     name: 'Core',
    //     networkAlias: 'CORE',
    //     iconName: 'core',
    //     networkColor: '#FF7324',
    //     networkFontColor: '#FF7324',
    //     network: NetworkNamesEnum.core,
    //     chainId: NetworkChainIdEnum.core,
    // },
    {
        name: 'Solana',
        networkAlias: 'SOL',
        iconName: 'solana',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.solana,
        chainId: NetworkChainIdEnum.solana,
        coingeckoId: CoingeckoNetworks.Solana,
    },
    {
        name: 'Blast',
        networkAlias: 'ETH',
        iconName: 'blast',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.blast,
        chainId: NetworkChainIdEnum.blast,
    },
    // {
    //     name: 'Hedera',
    //     networkAlias: 'HBAR',
    //     iconName: 'hedera',
    //     networkColor: '#01889F',
    //     networkFontColor: '#000',
    //     network: NetworkNamesEnum.hedera,
    //     chainId: NetworkChainIdEnum.hedera,
    // },
    {
        name: 'TON',
        networkAlias: 'TON',
        iconName: 'ton',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.ton,
        chainId: NetworkChainIdEnum.ton,
    },
    {
        name: 'HAQQ',
        networkAlias: 'ISLM',
        iconName: 'haqq',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.haqq,
        chainId: NetworkChainIdEnum.haqq,
    },
    {
        name: 'Vitruveo',
        networkAlias: 'VTRU',
        iconName: 'vtru',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.vtru,
        chainId: NetworkChainIdEnum.vtru,
    },
    {
        name: 'Saakuru',
        networkAlias: 'OAS',
        iconName: 'saakuru',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.saakuru,
        chainId: NetworkChainIdEnum.saakuru,
    },
    {
        name: 'Tron',
        networkAlias: 'TRX',
        iconName: 'tron',
        networkColor: '#01889F',
        networkFontColor: '#000',
        network: NetworkNamesEnum.tron,
        chainId: NetworkChainIdEnum.tron,
    },
    {
        name: 'Mode',
        networkAlias: 'ETH',
        iconName: 'mode',
        networkColor: '#5170AD',
        networkFontColor: '#5170AD',
        network: NetworkNamesEnum.mode,
        chainId: NetworkChainIdEnum.mode,
    },
    {
        name: 'Mantle',
        networkAlias: 'MNT',
        iconName: 'mantle',
        networkColor: '#5170AD',
        networkFontColor: '#5170AD',
        network: NetworkNamesEnum.mantle,
        chainId: NetworkChainIdEnum.mantle,
    },
];

export const SUPPORTED_CEXES: CexItem[] = [
    {
        name: 'Gate.io',
        networkAlias: 'gate',
        iconName: 'gateio',
        network: CexNamesEnum.gateio,
    },
    {
        name: 'Binance',
        networkAlias: 'binance',
        iconName: 'binance',
        network: CexNamesEnum.binance,
    },
    {
        name: 'MEXC Global',
        networkAlias: 'mxc',
        iconName: 'mexc',
        network: CexNamesEnum.mexc,
    },
    {
        name: 'Huobi',
        networkAlias: 'huobipro',
        iconName: 'huobipro',
        network: CexNamesEnum.huobipro,
    },
    {
        name: 'KuCoin',
        networkAlias: 'kucoin',
        iconName: 'kucoin',
        network: CexNamesEnum.kucoin,
    },
    {
        name: 'Whitebit',
        networkAlias: 'whitebit',
        iconName: 'whitebit',
        network: CexNamesEnum.whitebit,
    },
    {
        name: 'OKX',
        networkAlias: 'okx',
        iconName: 'okx',
        network: CexNamesEnum.okx,
    },
    {
        name: 'BitMart',
        networkAlias: 'bitmart',
        iconName: 'bitmart',
        network: CexNamesEnum.bitmart,
    },
    {
        name: 'ByBit',
        networkAlias: 'bybit',
        iconName: 'bybit',
        network: CexNamesEnum.bybit,
    },
    {
        name: 'Bitget',
        networkAlias: 'bitget',
        iconName: 'bitget',
        network: CexNamesEnum.bitget,
    },
]
