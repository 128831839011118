import {AfterViewInit, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {IconInterface, Icons, IconsColor} from "@shared/modules/icons/models/icons.models";
import {ICONS_LIST} from "@shared/modules/icons/static/icons.list";

@Component({
    selector: 'app-icons',
    templateUrl: './icons.component.html',
    styleUrls: ['./icons.component.less'],
})
export class IconsComponent implements AfterViewInit, OnChanges {
    icon: IconInterface | null;
    iconsList = ICONS_LIST;

    private static id: number = 0;

    componentId: number = 0;

    @Input() name: Icons | string;
    @Input() height: number | string;
    @Input() width: number | string;
    @Input() color: IconsColor;
    @Input() strokeColor: IconsColor;
    @ViewChild(`icon`, {read: ElementRef}) elemRef: ElementRef<HTMLElement>;

    constructor() {
        this.componentId = ++IconsComponent.id;
    }

    ngAfterViewInit() {
        this.manageIcon();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.manageIcon();
    }

    private manageIcon() {
        this.icon = this.iconsList.find(i => i.name === this.name) || null;
        const iconDiv = this.elemRef?.nativeElement;

        if (!iconDiv) {
            return;
        }
        iconDiv.innerHTML = this.icon?.data || '';
        const svgIcon = iconDiv.getElementsByTagName('svg')[0];
        if (!svgIcon) {
            return;
        }
        if (!this.icon?.notToFill) {
            svgIcon.setAttribute('fill', this.color || '#000000');
        }
        if (this.height) {
            svgIcon.setAttribute('height', this.height.toString());
        }
        if (this.width) {
            svgIcon.setAttribute('width', this.width.toString())
        }
        const childrenList = svgIcon.children;
        for (let i=0; i < childrenList.length; i++) {
            if (!!childrenList.item(i)?.getAttribute('stroke')) {
                childrenList.item(i)?.setAttribute('stroke', this.strokeColor || this.color || '#000000' );
            }
        }
    }

}
