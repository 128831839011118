import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from './core/core.module';
import {AuthcallbackComponent} from './page/authcallback/authcallback.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {en_US, NZ_I18N} from 'ng-zorro-antd/i18n';
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {HeaderModule} from './page/header/header.module';
import {CustomLoaderModule, LoaderService} from "@shared/modules/custom-loader";
import {NavigationMenuModule} from "./page/navigation-menu/navigation-menu.module";

registerLocaleData(en);

@NgModule({
    declarations: [
        AppComponent,
        AuthcallbackComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        CoreModule.forRoot(),
        BrowserAnimationsModule,
        HeaderModule,
        NzLayoutModule,
        CustomLoaderModule,
        // NavigationMenuModule,
    ],
    providers: [
        { provide: NZ_I18N, useValue: en_US },
        LoaderService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}
