import {NetworksAssets, SUPPORTED_CEXES, SUPPORTED_NETWORKS} from "@shared/constants/networks.const";
import {CexNamesEnum, NetworkNamesEnum} from "@shared/enums/networks.enum";
import {ArbitrageDexNames, CoingeckoDexNamesEnum, DexNamesEnum} from "@shared/enums/dex-names.enum";
import {DEX_VALUES} from "@shared/constants/dex-values";
import {ArbProjectsDeployStatusEnum} from "@shared/enums/arbitrage-project-deploy-status.enum";
import {AddArbitrageProjectWizardStepsEnum} from "@shared/enums/create-arbitrage-rpoject-wizard-steps";
import {ArbProjectsStatusEnum} from "@shared/enums/arbitrage-projects-status.enum";

export interface CoinGeckoCoinInterface {
    readonly id: string;
    readonly name: string;
    readonly symbol: string;
}
export interface CoinInfoInterface extends CoinGeckoCoinInterface{
    readonly tickers: TickersInterface[];
}

// export interface PlatformInterface {
//     [asset: string]: string;
// }

export interface TickersInterface extends CoinNamesInterface {
    readonly base: string;
    readonly coin_id: string;
    readonly market: MarketInterface;
    readonly target: string;
    readonly target_coin_id: string;
    readonly directPair: boolean;
    readonly cost_to_move_down_usd: number;
    readonly cost_to_move_up_usd: number;
    readonly converted_last: Converted;
    readonly converted_volume: Converted;
    readonly bid_ask_spread_percentage: number;
    readonly last_traded_at: string;
    readonly last: number;
    readonly volume: number;
    readonly symbol?: string;
}

export interface Converted {
    btc: number;
    eth: number;
    usd: number;
}

export class MarketMetricsItem {
    constructor(
        public readonly base: string,
        public readonly baseSymbol: string,
        public readonly baseId: string,
        public readonly target: string,
        public readonly targetSymbol: string,
        public readonly targetId: string,
        // public readonly network: NetworkNamesEnum | CexNamesEnum | string,
        public readonly exchange: CexNamesEnum | DexNamesEnum | string,
        public readonly price: number,
        public readonly priceByQuote: number,
        public readonly spread: number,
        public readonly upDepth: number,
        public readonly downDepth: number,
        public readonly volumeUsd: number,
        public readonly volume: number,
        public readonly lastTraded: string,
    ) {
    }
}

export interface MarketInterface {
    identifier: CoingeckoDexNamesEnum | string;
    readonly name: string;
    readonly logo: string;
}

export interface CoinExtendedInfoInterface {
    readonly id: string;
    readonly symbol: string;
    readonly name: string;
    readonly asset_platform_id: [keyof NetworksAssets];
    // readonly platforms: PlatformInterface;
}

export interface CoinNamesInterface {
    base: string;
    // baseCoinId: string;
    // baseName: string;
    quote: string;
    // quoteCoinId: string;
    // quoteName: string;
}

export interface SortedPairsInterface {
    readonly network: NetworkNamesEnum | CexNamesEnum;
    // readonly networkIcon: string;
    readonly exchanges: ExchangeInterface<DexNamesEnum>[];
    readonly pairs: PairInterface[];
}

export interface ExchangeInterface<T> {
    readonly market: T;
    // readonly marketIcon: string;
}

export interface PairInterface extends CoinNamesInterface, ExchangeInterface<DexNamesEnum | CexNamesEnum> {
    id: number;
    name: string;
    // exchange: string;
    // directPair: boolean;
}

export interface PairsInterface extends PairInterface,ExchangeInterface<DexNamesEnum | CexNamesEnum> {
    network: NetworkNamesEnum | CexNamesEnum;
}

export interface DexTickersInterface {
    readonly tokenName: string,
    readonly dexInfo: DexInterface[],
}

export interface DexInterface {
    readonly dexName: DexNamesEnum,
    readonly pairName: string,
}

export class WizardDexInfo {
    constructor(
        public readonly networkName: string,
        public readonly address: string,
    ) {
    }
}

export class WizardCexInfo {
    constructor(
        public readonly cexName: string,
        public readonly baseTicker: string,
    ) {
    }
}

export interface CoinInfoTickersInterface {
    readonly name: string;
    readonly tickers: TickersInterface[];
}

export interface RouteInterface {
    id: number,
    sell: PairsInterface,
    buy: PairsInterface,
}

export class RoutesForm {
    constructor(
        public readonly name: string,
        public readonly token: string,
        public readonly networks: RoutesNetworksForm[],
        public readonly cexesSetup: RoutesCexesSetupForm[],
        public readonly routes: RoutesArrayForm[],
        public readonly wallet: string[],
        // public readonly isSeparateFee: boolean,
        // public readonly generalFee?: string | null,
        public readonly id?: number,
    ) {
    }
}

export class RoutesNetworksForm {
    constructor(
        public readonly address: string,
        // public readonly tokenFee: string,
        public readonly network: string,
        public readonly minBase: string,
        public readonly minQuote: string,
        public readonly optimalBase: string,
        public readonly optimalQuote: string,
        public readonly checked: boolean,
    ) {
    }
}

export class RoutesCexesSetupForm {
    constructor(
        public readonly network: CexNamesEnum,
        // public readonly networkIcon: string,
        public readonly base: string,
        public readonly quote: RoutesCexesSetupQouteForm[],
        public readonly tradingFee: string,
        public readonly apiKey: string,
        public readonly secret: string,
        public readonly minBase: string,
        public readonly minQuote: string,
        public readonly optimalBase: string,
        public readonly optimalQuote: string,
        public readonly passphrase?: string,
    ) {
    }
}

export class RoutesCexesSetupQouteForm {
    constructor(
        public readonly ticker: string,
        public readonly buyFee: string,
        public readonly sellFee: string,
    ) {
    }
}

export class RoutesArrayForm {
    constructor(
        public readonly id: number,
        public readonly min: string,
        public readonly max: string,
        public readonly priority: number,
        public readonly active: boolean,
        public readonly sell: NetworkNamesEnum | CexNamesEnum,
        public readonly buy: NetworkNamesEnum | CexNamesEnum,
        public readonly step: number,
    ) {
    }
}

export interface RoutesCreateDto {
    readonly id: number | null;
    readonly name: string;
    readonly fixingQuote: string;
    readonly markets: RoutesPayloadMarket[];
    readonly routes: RoutesPayloadPair[];
    readonly adminWallets: string[];
    readonly createdAt: string;
    readonly updatedAt: string;
    readonly wizardStep: number;
    status?: ArbProjectsStatusEnum;
}

export class RoutesPayload {
    public readonly id: number | null;
    public readonly name: string;
    public readonly fixingQuote: string;
    public readonly markets: RoutesPayloadMarket[];
    public readonly routes: RoutesPayloadPair[];
    public readonly adminWallets: string[];
    // public readonly createdAt?: string;
    // public readonly updatedAt?: string;
    constructor(
        public readonly tenantId: number,
        form: RoutesForm,
    ) {
        this.id = form?.id || null;
        this.name = form?.name;
        this.fixingQuote = form?.token;
        this.markets = this.getMarketsFromForm(form);
        this.routes = this.getRoutes(form.routes);
        this.adminWallets = form?.wallet;
    }

    private getMarketsFromForm(
        form: RoutesForm,
    ): RoutesPayloadMarket[] {
        let markets: RoutesPayloadMarket[] = [];
        form.networks.forEach(dexNetwork => {
            if (!dexNetwork.checked) {
                return;
            }
            markets.push(new RoutesPayloadMarket(
                dexNetwork.address,
                null,
                +dexNetwork.minBase,
                +dexNetwork.minQuote,
                +dexNetwork.optimalBase,
                +dexNetwork.optimalQuote,
                this.getDexNetworkName(dexNetwork),
            ))
        });
        form.cexesSetup.forEach(cexNetwork => {
            markets.push(new RoutesPayloadMarket(
                cexNetwork.base,
                new RoutesCexCredentials(cexNetwork?.apiKey, cexNetwork?.secret, cexNetwork?.passphrase),
                +cexNetwork.minBase,
                +cexNetwork.minQuote,
                +cexNetwork.optimalBase,
                +cexNetwork.optimalQuote,
                this.getCexNetworkName(cexNetwork),
            ))
        });
        return markets;
    }

    private getDexNetworkName(dexNetwork: RoutesNetworksForm): NetworkNamesEnum | null {
        return SUPPORTED_NETWORKS.find(network => network.name === dexNetwork.network)?.network || null;
    }

    private getCexNetworkName(cexNetwork: RoutesCexesSetupForm): CexNamesEnum | null {
        return SUPPORTED_CEXES.find(network => network.network === cexNetwork.network)?.network || null;
    }

    // private getNetworkPairs(network: RoutesNetworksForm | RoutesCexesSetupForm, pairs: PairsInterface[]): RoutesMarketPayloadPair[] {
    //     return pairs.filter(pair => pair.network === network.network).map(pair => new RoutesMarketPayloadPair(
    //         pair.id,
    //         new PairTokensInfo(
    //             pair.base,
    //             pair.baseCoinId,
    //             pair.baseName,
    //             pair.quote,
    //             pair.quoteCoinId,
    //             pair.quoteName,
    //         ),
    //         pair.market,
    //         pair.marketIcon,
    //         +(pair.buyTradingFee || 0),
    //         +(pair.sellTradingFee || 0),
    //     ))
    // }

    public getRoutes(routes: RoutesArrayForm[]): RoutesPayloadPair[] {
        console.log(routes);
        return routes.map(route => new RoutesPayloadPair(
            route.id,
            route.active,
            route.priority,
            +route.min,
            +route.max,
            route.buy,
            route.sell,
            +route.step,
        ))
    }
}



export class RoutesPayloadPair {
    constructor(
        public readonly id: number,
        public readonly isActive: boolean, // from "status": "active",
        public readonly priority: number,
        public readonly minSize: number,
        public readonly maxSize: number,
        public readonly buy: NetworkNamesEnum | CexNamesEnum,
        public readonly sell: NetworkNamesEnum | CexNamesEnum,
        public readonly step: number,
        // public readonly createdAt?: string,
        // public readonly updatedAt?: string,
    ) {
    }
}

export class RoutesMarketPayloadPair {
    constructor(
        public readonly id: number,
        public readonly tokens: PairTokensInfo,
        public readonly exchange: DexNamesEnum | CexNamesEnum,  // exchange to market renamed
        public readonly marketIcon: string,
    ) {
    }
}

export class PairTokensInfo {
    constructor(
        public readonly base: string,
        public readonly baseCoinId: string,
        public readonly baseName: string,
        public readonly quote: string,
        public readonly quoteCoinId: string,
        public readonly quoteName: string,
    ) {
    }
}

export class RoutesPayloadMarket {
    constructor(
        public readonly baseToken: string,
        public readonly credentials: RoutesCexCredentials | null,
        public readonly minBase: number,
        public readonly minQuote: number,
        public readonly optimalBase: number,
        public readonly optimalQuote: number,
        public readonly network: NetworkNamesEnum | CexNamesEnum | null,
        public readonly quotes?: MarketQuotesDto[],
        public readonly feePercent?: number | null,
        public readonly id?: number,
        public readonly baseSymbol?: string,
        public readonly gasWallet?: string,
        public readonly vault?: string,
        public readonly uniqueDexNames?: ArbitrageDexNames[],
        public readonly tokens?: any,
    ) {
    }
}

export class MarketQuotesDto {
    constructor(
        public readonly contract: string,
        public readonly exchange: string,
        public readonly symbol: string,
    ) {
    }
}

export class RoutesCexCredentials {
    constructor(
        public readonly apiKey: string | null,
        public readonly secret: string | null,
        public readonly passphrase: string | null,
    ) {
    }
}

export interface ArbProjectBalancesDeploy {
    readonly address: string;
    readonly balances: ArbProjectBalance[];
}

export interface ArbProjectBalance {
    readonly address: string;
    readonly balance: string;
    readonly gasLimit: string;
    readonly gasPrice: string;
    readonly gasRequired: string;
    readonly network: NetworkNamesEnum;
}

export interface ArbProjectVaultsDeploymentsDto {
    readonly status: ArbProjectsDeployStatusEnum,
    readonly deployed: number,
    readonly deployments: ArbProjectVaultsDeploymentDto[],
}

export interface ArbProjectVaultsDeploymentDto {
    network: NetworkNamesEnum;
    address?: string;
    deploymentStatus: boolean;
}

export class ArbitrageProjectToken {
    constructor(
        public readonly id: number,
        public readonly name: string,
    ) {
    }
}

export class CreateArbitrageProjectStep {
    constructor(
        public readonly id: AddArbitrageProjectWizardStepsEnum,
        public readonly title: string,
        public readonly description: string,
        public readonly icon: string,
    ) {
    }
}
