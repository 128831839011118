import {Injectable} from '@angular/core';

@Injectable()
export class LocalStorageService {

  private readonly localStorage = window.localStorage;

  constructor() { }

  public getItem<T>(key: string): T | null | undefined {
      const item = this.localStorage.getItem(key);
      return item ? JSON.parse(item) as T : null;
  }

  public setItem<T>(key: string, value: T): void {
      this.localStorage.setItem(key, JSON.stringify(value));
  }

  public removeItem(key: string): void {
      this.localStorage.removeItem(key);
  }

  public clearStorage(): void {
      this.localStorage.clear();
  }
}
