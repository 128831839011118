export enum RolesEnum {
    TenantAdmin = 'TenantAdmin',
    ProjectAdmin = 'ProjectAdmin',
    ProjectMember = 'ProjectMember',
    constable = 'constable',
    gasWithdrawer = 'GasWithdrawer',
    ArbProjectAdmin = 'ArbProjectAdmin',
    ArbProjectMember = 'ArbProjectMember',
    ArbGasWithdrawer = 'ArbGasWithdrawer',
    ViewClientDashboard = 'DashboardViewer',
}
