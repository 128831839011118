import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconsComponent } from './components/icons/icons.component';
import { IconsSamplesComponent } from './components/icons-samples/icons-samples.component';



@NgModule({
    declarations: [
        IconsComponent,
        IconsSamplesComponent
    ],
    exports: [
        IconsComponent
    ],
    imports: [
        CommonModule
    ]
})
export class IconsModule { }
