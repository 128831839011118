import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { ConnectMetamaskModalComponent } from './connect-metamask-modal.component';
import { BackgroundImageModule } from '../../directives/background-image/background-image.module';
import { FlexModule } from '@angular/flex-layout';

@NgModule({
    declarations: [ConnectMetamaskModalComponent],
    exports: [ConnectMetamaskModalComponent],
    imports: [CommonModule, NzModalModule, BackgroundImageModule, FlexModule],
})
export class ConnectMetamaskModalModule {
}
