import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { BasePermission } from './base-permission';
import { PermissionService } from '../../../core/permission.service';

/**
 * Enables the content interraction if user matches permissions.
 * See syntax in BasePermission class
 */
@Directive({ selector: '[appEnableFor]' })
export class EnableForDirective extends BasePermission {
    constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        permissionService: PermissionService,
    ) {
        super(templateRef, viewContainer, permissionService);
    }

    @Input() set appEnableFor(input: any) {
        const hasPermission = this.hasPermission(input);
        this.toggleVisibility(true);
        this.toggleEnabled(hasPermission);
    }
}
