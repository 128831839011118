import {ArbProjectsStatusEnum} from "@shared/enums/arbitrage-projects-status.enum";
import {ArbitrageTenantStatusEnum} from "@shared/enums/arbitrage-tenant-status.enum";
import {AddArbitrageProjectWizardStepsEnum} from "@shared/enums/create-arbitrage-rpoject-wizard-steps";
import {ArbitrageDexNames} from "@shared/enums/dex-names.enum";
import {CexNamesEnum, NetworkNamesEnum} from "@shared/enums/networks.enum";
import {ARBITRAGE_DEX_VALUES} from "@shared/constants/dex-values";
import {NETWORKS_LIST, SUPPORTED_NETWORKS} from "@shared/constants/networks.const";

export interface ArbitrageTenantsInterface {
    id: number;
    name: string;
    status: ArbitrageTenantStatusEnum;
}

export interface ArbProjectsInterface {
    id: number;
    fixingQuote: string;
    tenantId: number;
    name: string;
    status: ArbProjectsStatusEnum,
    markets: ArbProjectsMarketsInterface[];
    wizardStep: AddArbitrageProjectWizardStepsEnum;
}

export interface ArbProjectEdit {
    id: number;
    nameField: string;
}

export interface ArbProjectsMarketsInterface {
    readonly base: string;
    readonly baseSymbol: string;
    readonly marketName: NetworkNamesEnum | CexNamesEnum;
    readonly uniqueDexNames: ArbitrageDexNames[];
}


export interface ArbProjectBalancesInterface {
    cexes: {[key: string]: number};
    dexes:  {[key: string]: number};
    gas:  {[key: string]: number};
}

// export interface ArbProjectBalancesItemInterface {
//     [key: string]: number
// }

export interface ArbProjectMarketBalancesInterface {
    readonly id: number;
    readonly marketsBalances: MarketsBalancesInterface[];
}

export interface MarketsBalancesInterface {
    readonly marketName: CexNamesEnum | NetworkNamesEnum;
    readonly balanceBase: number;
    readonly balanceFixing: number;
    readonly balanceUsd: number;
    readonly gas: number;
    readonly gasUsd: number;
}


export interface ArbProjectHistoryInterface {
    readonly id: number;
    readonly type: any; // sell/buy
    readonly status: any // success/fail/etc
    readonly marketName: CexNamesEnum | NetworkNamesEnum;
    readonly base: string;
    readonly basePrice: number;
    readonly basePriceUsd: number;
    readonly baseVolume: number;
    readonly fixingVolume: number;
    readonly baseVolumeUsd: number;
    readonly gasPrice: number;
    readonly gasPriceUsd: number;
    readonly baseTradingFee: number;
    readonly fixingTradingFee: number;
    readonly tradingFeeUsd: number;
    readonly baseRebalanceFee: number;
    readonly fixingRebalanceFee: number;
    readonly rebalanceFeeUsd: number;
    readonly profitUsd: number;
}


export class ArbProjectsListItem {
    public readonly id: number;
    public readonly tenantId: number;
    public name: string;
    public status: ArbProjectsStatusEnum;
    public readonly dexes: string[];
    public readonly cexes: string[];
    public readonly markets: ArbProjectsMarketsInterface[];
    public readonly funds: ArbProjectBalancesInterface | null = null;
    public readonly tradeData: ArbProjectsTradeData | null = null;
    public searchedItems: number | null = null;
    public wizardStep: AddArbitrageProjectWizardStepsEnum;
    constructor(
        project: ArbProjectsInterface,
        balances: ArbProjectMarketBalancesInterface | null,
        arbProjectsTradeData: ArbProjectHistoryInterface[] | null,
    ) {
        this.id = project.id;
        this.tenantId = project.tenantId;
        this.name = project.name;
        this.status = project.status;
        this.dexes = project.markets.reduce((acc, curr) => {
            return curr.uniqueDexNames?.length ? acc.concat(curr.uniqueDexNames?.filter((item) => acc.indexOf(item) < 0)) : acc;
        }, []).map(arbitrageDex => ARBITRAGE_DEX_VALUES[arbitrageDex]?.name);
        this.cexes = project.markets.map(market => market.marketName).filter((marketName: NetworkNamesEnum | CexNamesEnum) => marketName in CexNamesEnum);
        this.markets = project.markets;
        this.funds = this.getFunds(balances, project);
        this.wizardStep = project.wizardStep;
        this.tradeData = this.getTradesData(arbProjectsTradeData);
    }

    private getFunds(balances: ArbProjectMarketBalancesInterface | null, project: ArbProjectsInterface): ArbProjectBalancesInterface | null {
        if (!balances?.marketsBalances?.length) {
            return null;
        }
        return balances?.marketsBalances?.reduce((acc: ArbProjectBalancesInterface, curr: MarketsBalancesInterface) => {
            const projMarket = project?.markets?.find(el => el.marketName === curr.marketName);
            if (!projMarket) {
                return acc;
            }
            if ( CexNamesEnum[curr?.marketName] !== undefined) {
                const existedBase = acc?.cexes[projMarket.base];
                if (existedBase) {
                    acc.cexes[projMarket.base] += curr.balanceBase
                } else {
                    acc.cexes[projMarket.base] = curr.balanceBase;
                }
                const existedFixing = acc.cexes[project.fixingQuote];
                if (existedFixing) {
                    acc.cexes[project.fixingQuote] += curr.balanceFixing;
                } else {
                    acc.cexes[project.fixingQuote] = curr.balanceFixing;
                }
            } else {
                const existedBase = acc?.dexes[projMarket.baseSymbol];
                if (existedBase) {
                    acc.dexes[projMarket.base] += curr.balanceBase
                } else {
                    acc.dexes[projMarket.baseSymbol] = curr.balanceBase;
                }
                const existedFixing = acc.dexes[project.fixingQuote];
                if (existedFixing) {
                    acc.dexes[project.fixingQuote] += curr.balanceFixing;
                } else {
                    acc.dexes[project.fixingQuote] =  curr.balanceFixing;
                }
                const network = SUPPORTED_NETWORKS.find(network => network.network === curr.marketName);
                const existingGas = acc.gas[network.networkAlias];
                if (existingGas) {
                    acc.gas[network.networkAlias] += curr.gas;
                } else {
                    acc.gas[network.networkAlias] = curr.gas;
                }
            }
            return acc;
        }, {dexes: {}, cexes: {}, gas: {}} as ArbProjectBalancesInterface)
        // // TODO ADD PRICE FROM COINGECKO
        // const basePrice = 1;
        // const fixPrice = 1;
        // const gasPrice = 0.01;
        // return balances?.marketsBalances?.reduce((acc: ArbProjectBalancesInterface, curr: MarketsBalancesInterface) => {
        //     if ( CexNamesEnum[curr?.marketName] !== undefined) {
        //         acc.cexes += (curr.balanceBase || 0) * basePrice + (curr?.balanceFixing || 0) * fixPrice;
        //     } else {
        //         acc.dexes += (curr.balanceBase || 0) * basePrice + (curr?.balanceFixing || 0) * fixPrice;
        //     }
        //     acc.gas += curr.gas ? curr.gas * gasPrice : 0;
        //     return acc;
        // }, {dexes: 0, cexes: 0, gas: 0} as ArbProjectBalancesInterface);
    }

    private getTradesData(data: ArbProjectHistoryInterface[] | null): ArbProjectsTradeData | null {
        if (!data?.length) {
            return null;
        }
        let wins = 0;
        return data.reduce((acc: ArbProjectsTradeData, curr: ArbProjectHistoryInterface) => {
            acc = new ArbProjectsTradeData(
                (acc?.volume || 0) + curr.baseVolumeUsd,
                (acc?.netResult || 0) + curr.profitUsd,
                (acc?.trades || 0) + 1,
                curr.profitUsd > 0 ? ((acc?.winRate || 0) * wins + 1) / (wins + 1) : (acc?.winRate || 0),
            )
            wins = curr.profitUsd > 0 ? wins++ : wins;
            return acc;
        }, null);
    }
}

export class ArbProjectsLiveDataStatistics {
    constructor(
        public readonly started: number,
        public readonly needAttention: number,
        public readonly stopped: number,
    ) {
    }
}

export class ArbProjectsTradeData {
    constructor(
        public readonly volume: number,
        public readonly netResult: number,
        public readonly trades: number,
        public readonly winRate: number,
    ) {
    }
}

