// export const auth_tenant = 'http://dark-forest-seller/tenantId';
// export const auth_roles = 'http://dark-forest-seller/roles';
// export const availableProjects = 'http://dark-forest-seller/projects';
// export const email = 'email';
// export const logoUrl = 'http://dark-forest-seller/logoUrl';
// export const arbitrageTenant = 'http://dark-forest-seller/arbitrageTenant';
// export const arbitrageProjects = 'http://dark-forest-seller/arbitrageProjects';

export enum AuthMetaData {
    auth_tenant = 'http://dark-forest-seller/tenantId',
    auth_roles = 'http://dark-forest-seller/roles',
    availableProjects = 'http://dark-forest-seller/projects',
    email = 'email',
    logoUrl = 'http://dark-forest-seller/logoUrl',
    arbitrageTenant = 'http://dark-forest-seller/arbitrageTenant',
    arbitrageProjects = 'http://dark-forest-seller/arbitrageProjects',
    withdrawProjects = 'http://dark-forest-seller/withdrawProjects',
    viewProfiles = 'http://dark-forest-seller/view-profiles',
}
