export enum ArbProjectsStatusEnum {
    started = 'started',
    stopped = 'stopped',
    attention = 'attention',
    archived = 'archived',
    deleted = 'deleted',
}

export enum ArbRoutesStatusEnum {
    active = 'active',
    notEnoughFunds = 'notEnoughFunds',
    disabled = 'disabled',
}
