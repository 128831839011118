import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { PermissionsModule } from '@shared/directives/permissions/permission.module';
import { FlexModule } from '@angular/flex-layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { ConnectMetamaskModalModule } from '@shared/modals/connect-metamask-modal/connect-metamask-modal.module';
import { BackgroundImageModule } from '@shared/directives/background-image/background-image.module';
import {IconsModule} from "@shared/modules/icons/icons.module";

@NgModule({
    declarations: [HeaderComponent],
    exports: [HeaderComponent],
    imports: [
        CommonModule,
        NzLayoutModule,
        PermissionsModule,
        FlexModule,
        NzButtonModule,
        ConnectMetamaskModalModule,
        BackgroundImageModule,
        IconsModule,
    ],
})
export class HeaderModule {}
