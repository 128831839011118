<div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
    <div *ngIf="!!selectedNetwork">
        Currently on: <span class="selected-network-name">{{selectedNetwork.name}}</span>
    </div>
    <div fxLayout="column" fxLayoutAlign="start center" fxLayoutGap="10px">
        <div *ngFor="let network of supportedNetworks"
             fxLayout="row" fxLayoutAlign="start center"
             fxLayoutGap="10px"
             class="item-wrapper"
             [class.selected]="network.chainId === selectedNetwork?.chainId"
             [ngStyle]="{
                'background-color': network.chainId === selectedNetwork?.chainId ? network.networkColor : '#ffffff'
             }"
             (click)="selectNetwork(network)"
        >
            <div class="image" [appBackgroundImage]="'assets/icons/networks/' + network.iconName + '.png'"></div>
            <div>{{network.name}}</div>
        </div>
    </div>
</div>

