import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-icons-samples',
    templateUrl: './icons-samples.component.html',
    styleUrls: ['./icons-samples.component.less'],
})
export class IconsSamplesComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
