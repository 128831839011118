import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';

@Injectable()
export class UrlParamsService {
    constructor(private route: ActivatedRoute, private router: Router) {}

    getAllParams() {
        let params = {};
        let route: ActivatedRouteSnapshot | null = this.router.routerState.snapshot.root;

        do {
            for (let key in route.params) {
                params[key] = route.params[key];
            }

            route = route.firstChild;
        } while (route);

        return params;
    }

    getAllData() {
        let data = {};
        let route: ActivatedRouteSnapshot | null = this.router.routerState.snapshot.root;

        do {
            for (let key in route.data) {
                data[key] = route.data[key];
            }

            route = route.firstChild;
        } while (route);

        return data;
    }

    getParam(key) {
        return this.getAllParams()[key];
    }

    getData(key) {
        return this.getAllData()[key];
    }
}
