import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";
import {
    CoinExtendedInfoInterface, CoinGeckoCoinInterface,
    CoinInfoInterface,
    CoinInfoTickersInterface
} from "../../page/arbitrage/modules/arbitrage-tenant/modules/create-arbitrage-project-wizard/models/coingecko.model";
import {CoingeckoDexNamesEnum} from "@shared/enums/dex-names.enum";
import {CexNamesEnum} from "@shared/enums/networks.enum";
import {CoingeckoCexes} from "@shared/constants/coingecko-info";

@Injectable()
export class CoingeckoApiService {
    apiUrl = 'https://api.coingecko.com/api/v3';

    constructor(
        private http: HttpClient,
    ) {}

    getCoinInfoByContract(platformId: string, address: string): Observable<CoinGeckoCoinInterface | null> {
        return this.http.get<CoinGeckoCoinInterface>(this.apiUrl + `/coins/${platformId}/contract/${address}`,{
            params: {
                // include_exchange_logo: true,
                localization: false,
                // market_data: true,
                // community_data: true,
                // developer_data: true,
                // sparkline: true,
                // depth: true,
                page: 2,
                tickers: false,
                // exchange_ids: ['binance-smart-chain','gateio']
            }
        });
    }

    getCoinInfo(coinId: string): Observable<CoinExtendedInfoInterface | null> {
        return this.http.get<CoinExtendedInfoInterface>(this.apiUrl + `/coins/${coinId}`,{
            params: {
                include_exchange_logo: true,
                localization: false,
                tickers: true,
                market_data: true,
                community_data: true,
                developer_data: true,
                sparkline: true,
                depth: true,
            }
        });
    }

    getCoinTickers(coinId: string, exchangeId?: (CoingeckoDexNamesEnum | CoingeckoCexes)[]): Observable<CoinInfoTickersInterface> {
        return this.http.get<CoinInfoTickersInterface>(this.apiUrl + `/coins/${coinId}/tickers`, {
            params: {
                exchange_ids: exchangeId.toString(),
                depth: true,
                market_data: true,
                developer_data: true,
            }
        })
    }

    searchToken(coinSymbol: string): Observable<any> {
        return this.http.get(this.apiUrl + `/search`, {
            params: {
                query: coinSymbol,
            }
        })
    }
}
