import { TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionService } from '../../../core/permission.service';
import { RolesEnum } from '../../enums/roles.enum';
import {PermissionsEnum} from "@shared/enums/permissions.enum";

/**
 * The base class for structural directives for permission handling
 *
 * ### Syntax
 *
 * - `<div *appShowFor="'role1'">...</div>`
 * - `<div *appShowFor="['role1', 'role2']">...</div>`
 * - `<div *appShowFor="['role1', 'role2']; priority: true">...</div>`
 * - `<div *appShowFor="{ users: ['user1'], roles: ['role1'] }">...</div>`
 * - `<div *appShowFor="{ users: 'user1', roles: 'role1' }">...</div>`
 *
 * - `<div template="appShowFor 'role1'">...</div>`
 * - `<div template="appShowFor ['role1', 'role2']">...</div>`
 * - `<div template="appShowFor ['role1', 'role2']; priority: true">...</div>`
 * - `<div template="appShowFor { users: ['user1'], roles: ['role1'] }">...</div>`
 * - `<div template="appShowFor { users: 'user1', roles: 'role1' }">...</div>`
 *
 * - `<template [appShowFor]="'role1'"><div>...</div></template>`
 * - `<template [appShowFor]="['role1', 'role2']"><div>...</div></template>`
 * - `<template [appShowFor]="{ users: ['user1'], roles: ['role1'] }"><div>...</div></template>`
 * - `<template [appShowFor]="{ users: 'user1', roles: 'role1' }"><div>...</div></template>`
 */
export class BasePermission {
    private hasView = false;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionService: PermissionService,
    ) {}

    hasPermission(input: any | string | string[]) {
        return this.isInRoles(input);
    }

    checkPermissions(input: any | string | string[] | PermissionsEnum | PermissionsEnum[]) {
        return this.permissionService.permissions?.includes(input);
    }

    denyPermission(input: any | string | string[]) {
        return !this.isInRoles(input);
    }

    isInRoles(roleInput: RolesEnum | RolesEnum[]): boolean {
        const roles = Array.isArray(roleInput) ? roleInput : [roleInput];

        return this.permissionService.isInRoles(roles);
    }

    toggleVisibility(show: boolean, elseTemplate?: TemplateRef<unknown>) {
        if (show && !this.hasView) {
            this.viewContainer.createEmbeddedView(this.templateRef);
            this.hasView = true;
        } else if (!show && !this.hasView && elseTemplate) {
            this.viewContainer.createEmbeddedView(elseTemplate);
            this.hasView = true;
        } else if (!show && this.hasView && !elseTemplate) {
            this.viewContainer.clear();
            this.hasView = false;
        }
    }

    toggleEnabled(enable: boolean) {
        const element = this.getElement();
        if (!(element && element.style)) {
            return;
        }

        if (enable) {
            element.style.pointerEvents = '';
        } else {
            element.style.pointerEvents = 'none';
        }
    }

    getElement(): any {
        return this.viewContainer.element.nativeElement.nextElementSibling;
    }
}
