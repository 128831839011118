export enum EthAccountTypeEnum {
    trader = 'trader',
    deployer = 'deployer',
    transit = 'transit',
    holder = 'holder',
    maker = 'maker',
}

export enum EthAccountStatusEnum {
    active = 'active',
    archived = 'archived'
}
