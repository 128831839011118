import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {catchError, Observable, of} from 'rxjs';
import {
    CexProfileDataDtoInterface,
    CexProfileExtendedInterface,
    CexProfileInterface, GetCexTokensPrice, GetCexTokensPriceResDto,
    GetProfileByTokenIdRespDto,
    GetTokenByProjectIdDto,
    ProfileDataDtoInterface,
    ProfileInterface,
    ProfilesInterface, ProfileType
} from '@shared/interfaces/profiles.interface';
import {GasEstimateInterface} from '@shared/interfaces/gas-estimate.interface';
import {RangeLimitCurrencyEnum} from '@shared/enums/range.enum';
import {ProjectsInterface} from "@shared/interfaces/projects.interface";
import {CreateProfileInterface} from "@shared/interfaces/create-profile.interface";
import {cleanObject} from "@shared/helpers/clean-object";
import {
    CreateCexProfileForm
} from "../../page/tenants/modules/tenant/modules/project/modules/profile/modules/add-profile-wizard/add-cex-profile/models/add-cex-profile.modal";

@Injectable()
export class ProjectApiService {


    constructor(private http: BaseService) {}

    getProject(projectId: number, tenantId: number): Observable<ProjectsInterface> {
        return this.http.get(`tenants/${tenantId}/projects/${projectId}`);
    }

    getProfiles(tenantId: number, projectId: number): Observable<(ProfileInterface | CexProfileInterface)[]> {
        return this.http.get(`tenants/${tenantId}/project/${projectId}/profiles`);
    }

    getTokens(tenantId: number, projectId: number): Observable<GetTokenByProjectIdDto[]> {
        return this.http.get(`tenants/${tenantId}/project/${projectId}/tokens`);
    }

    getTokenProfiles(tenantId: number, projectId: number, tokenId: number): Observable<GetProfileByTokenIdRespDto[]> {
        return this.http.get(`tenants/${tenantId}/project/${projectId}/tokens/${tokenId}`)
    }

    addProfile(
        tenantId,
        projectId,
        newProfile: CreateProfileInterface,
    ): Observable<ProfilesInterface> {
        const profile = cleanObject(newProfile);
        return this.http.create(
            `tenants/${tenantId}/project/${projectId}/profiles`,
            profile,
        );
    }

    addCexProfile(
        tenantId,
        projectId,
        newProfile: CreateCexProfileForm,
    ): Observable<CexProfileInterface[]> {
        const profile = cleanObject(newProfile);
        return this.http.create(
            `tenants/${tenantId}/project/${projectId}/profiles/create-cex`,
            profile,
        );
    }

    getGasEstimation(tenantId, projectId, profileId): Observable<GasEstimateInterface> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/gas`,
        );
    }

    getTokenPrice(
        tenantId,
        projectId,
        profileId,
        currency: RangeLimitCurrencyEnum,
    ): Observable<number> {
        const params = {type: currency};
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/getTokenInUSD`,
            {params}
        ).pipe(
            catchError(_ => of(0)),
        );
    }

    deleteProfile(tenantId: number, projectId, profileId: number): Observable<void> {
        return this.http.delete(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}`,
        );
    }

    deleteCexProfile(tenantId: number, projectId, profileId: number): Observable<void> {
        return this.http.delete(
            `tenants/${tenantId}/project/${projectId}/profiles/cex/${profileId}`,
        );
    }

    getProfileSummary(tenantId: number, projectId, profileId: number, payload?: any): Observable<ProfileDataDtoInterface | CexProfileDataDtoInterface | null> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/profile-summary`,
            {params: payload}
        ).pipe(
            catchError(err => {
                return of(null);
            })
        );
    }

    getPriceInQuote(tenantId, projectId, profileId): Observable<number> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/PriceInQuote`,
        ).pipe(
            catchError(_ => of(0)),
        );
    }

    getCexPrices(tenantId, projectId, profileId, pricesData: GetCexTokensPrice): Observable<GetCexTokensPriceResDto> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/cex-prices`,
            {params: {
                    base: pricesData.base,
                    quote: pricesData.quote,
                    exchange: pricesData.exchange,
            }}
        ).pipe(
            catchError(_ => of({priceQuote: 0, priceUsd: 0})),
        );
    }

    getGasPrice(tenantId, projectId, profileId): Observable<string> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/GasPrice`,
        ).pipe(
            catchError(_ => of('0')),
        );
    }

    getExtendedGasEstimation(tenantId, projectId, profileId): Observable<any> {
        return this.http.get(
            `tenants/${tenantId}/project/${projectId}/profiles/${profileId}/ExtendedGasEstimation`,
        ).pipe(
            catchError(_ => of(0)),
        );
    }

    getProjectWallets(tenantId, projectId): Observable<string[]> {
        return this.http.get(`tenants/${tenantId}/projects/${projectId}/wallets`,
        ).pipe(
            catchError(_ => of([])),
        );
    }
}
