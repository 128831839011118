export class Helper {
    static isiOSDevice: boolean = /Apple/i.test(navigator.vendor);


    static copyToBuffer(text: string) {
        let ta: HTMLTextAreaElement;

        ta = document.createElement('textarea');
        // Prevent zooming on iOS
        ta.style.fontSize = '12pt';
        // Reset box model
        ta.style.border = '0';
        ta.style.padding = '0';
        ta.style.margin = '0';
        // Move element out of screen horizontally
        ta.style.position = 'absolute';
        ta.style.left = '-9999px';
        // Move element to the same position vertically
        const yPosition = window.pageYOffset || document.documentElement.scrollTop;
        ta.style.top = yPosition + 'px';
        ta.setAttribute('readonly', '');

        ta.value = text;

        document.body.appendChild(ta);

        if (Helper.isiOSDevice) {
            const editable = ta.contentEditable;
            ta.contentEditable = 'true';

            const range = document.createRange();
            range.selectNodeContents(ta);

            const selection = window.getSelection();
            // @ts-ignore
            selection.removeAllRanges();
            // @ts-ignore
            selection.addRange(range);

            ta.setSelectionRange(0, 999999);
            ta.contentEditable = editable;

        } else {
            ta.select();
        }

        document.execCommand('copy');

        document.body.removeChild(ta);
    }
}
