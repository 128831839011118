import { RolesEnum } from '../../shared/enums/roles.enum';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { PermissionService } from '../../core/permission.service';
import {zip} from "rxjs";

@Component({
    selector: 'authcallback',
    template: '',
})
export class AuthcallbackComponent implements OnInit {
    constructor(public auth: AuthService, private router: Router, private permissions: PermissionService) {
    }

    ngOnInit() {
        zip([
            this.permissions.isReady,
            this.permissions.isPermissionsReady
        ]).subscribe(() => {
            this.permissions.navigateByPermissions();
        });
    }
}
