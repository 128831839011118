import {Injector, NgModule, Optional, SkipSelf} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoaderComponent} from './components/loader/loader.component';
import {BlockLoaderComponent} from './components/block-loader/block-loader.component';
import {LoaderDirective} from './directives/loader/loader.directive';
import {LoaderServiceInjector, setLoaderServiceInjector} from './models/loader-service.injector';

@NgModule({
    declarations: [LoaderComponent, LoaderDirective, BlockLoaderComponent],
    imports: [
        CommonModule
    ],
    exports: [LoaderComponent, LoaderDirective, BlockLoaderComponent],
})
export class CustomLoaderModule {

    constructor(
        private readonly injector: Injector,
        @Optional() @SkipSelf() private parentModule: CustomLoaderModule
    ) {
        this.setLoaderInjector();
    }

    private setLoaderInjector(): void {
        if (!this.parentModule && !LoaderServiceInjector) {
            setLoaderServiceInjector(this.injector);
        }
    }
}
