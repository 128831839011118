export class VaultContractFeaturesClass {
    constructor(
        private currentVersion: number,
    ) {
    }

    getCurrentVersion() {
        return this.currentVersion;
    }

    isSyncProxyReceiversSupports(): boolean {
        return this.currentVersion >= 3;
    }

    isReDeployProxyReceiversSupports(): boolean {
        return this.currentVersion >= 3;
    }

    isMainReceiverChangeSupports(): boolean {
        return this.currentVersion >= 4;
    }

    isSyncAssetPriceSupports(): boolean {
        return this.currentVersion === 1;
    }
}
