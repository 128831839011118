import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs/internal/observable/throwError';
import {environment} from '../../environments/environment';
import {v4 as uuidv4} from 'uuid';
import {AlertTypesEnum} from '../shared/enums/alert-types.enum';
import {NzNotificationService} from 'ng-zorro-antd/notification';
import {NavigateService} from "@core/services/navigate.service";

@Injectable()
export class BaseService {
    private _baseUrl = environment.baseUrl;
    private correlationId = uuidv4();

    constructor(
        private http: HttpClient,
        private notification: NzNotificationService,
        private readonly navigateService: NavigateService,
    ) {}

    get baseUrl(): string {
        return this._baseUrl;
    }

    getHeaders(customHeaders?: any) {
        let headers: any = {
            'Content-Type': 'application/json',
            'correlation-id': this.correlationId,
        };

        headers = { ...headers, ...customHeaders };

        return new HttpHeaders(headers);
    }

    get<T = any>(url: string, options?, baseUrl?: string, headers?) {
        const opts = this.extendOptions(options, headers);
        const requestUrl = this.defineUrl(baseUrl);

        return this.http
            .get<T>(`${requestUrl + url}`, opts)
            .pipe(catchError((err) => this.handleError(err)));
    }

    create<T = any>(url: string, body: any, options?, baseUrl?: string) {
        return this.createPost<T>(url, body, options, baseUrl);
    }

    update<T = any>(url: string, body: any, options?, baseUrl?: string) {
        const bodyString = JSON.stringify(body);
        const opts = this.extendOptions(options);
        const requestUrl = this.defineUrl(baseUrl);

        return this.http
            .put<T>(`${requestUrl + url}`, bodyString, opts)
            .pipe(catchError((err) => this.handleError(err)));
    }

    delete<T = any>(url: string, body?: any, options?, baseUrl?: string) {
        const opts = this.extendOptions(options);
        const requestUrl = this.defineUrl(baseUrl);

        return this.http
            .delete<T>(`${requestUrl + url}`, {
                ...opts,
                body,
            })
            .pipe(catchError((err) => this.handleError(err)));
    }

    filter<T = any>(url: string, criteria?: any, options?, baseUrl?: string) {
        return this.createPost<T>(url, criteria, options, baseUrl);
    }

    private defineUrl(url?: string) {
        return !!url ? url : this._baseUrl;
    }

    private extendOptions(o: HttpParams, customHeaders?) {
        const headers = this.getHeaders(customHeaders);
        const opts = { ...o, ...{ headers } };

        return opts;
    }

    private handleError(error) {
        if (error.status === 403) {
            this.navigateService.navigateToForbidden();

            return throwError(error || 'Server error');
        }

        if (error.status === 404) {
            this.navigateService.navigateToNotFound();

            return throwError(error || 'Server error');
        }

        if (error.status === 500) {
            this.navigateService.navigateToServerError();

            return throwError(error || 'Server error');
        }

        if (!!error.error.error) {
            this.notification.create(AlertTypesEnum.Error, error.error.error, error.error.message);
        } else {
            this.notification.create(AlertTypesEnum.Error, 'Error', 'Something went wrong');
        }

        return throwError(error || 'Server error');
    }

    private createPost<T = any>(url: string, body?: any, options?, baseUrl?: string) {
        const bodyString = JSON.stringify(body),
            opts = this.extendOptions(options),
            requestUrl = this.defineUrl(baseUrl);

        return this.http
            .post<T>(`${requestUrl + url}`, bodyString, opts)
            .pipe(catchError((err) => this.handleError(err)));
    }
}
