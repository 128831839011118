import {Injectable} from '@angular/core';
import {BaseService} from "@core/base.service";
import {Observable, of} from "rxjs";
import {
    ArbitrageTenantsInterface,
    ArbProjectHistoryInterface,
    ArbProjectMarketBalancesInterface,
    ArbProjectsInterface, MarketsBalancesInterface
} from "@shared/interfaces/arbitrage.interface";
import {ProjectsStatusEnum} from "@shared/enums/project-statuses.enum";
import {HttpParams} from "@angular/common/http";
import {ProjectActiveProfilesInterface} from "@shared/interfaces/project-active-profiles.interface";
import {ProfileMoveInterface} from "@shared/interfaces/profiles.interface";
import {
    ProjectsSettingsDto
} from "../../page/tenants/modules/tenant/modules/project-settings/models/project-settings-modal.models";
import {ArbProjectsStatusEnum} from "@shared/enums/arbitrage-projects-status.enum";
import {
    ArbProjectBalancesDeploy,
    ArbProjectVaultsDeploymentsDto,
    DexTickersInterface,
    RoutesCreateDto,
    RoutesPayload, RoutesPayloadPair,
    WizardCexInfo,
    WizardDexInfo
} from "../../page/arbitrage/modules/arbitrage-tenant/modules/create-arbitrage-project-wizard/models/coingecko.model";
import {CexNamesEnum} from "@shared/enums/networks.enum";
import {tap} from "rxjs/operators";
import {
    GetStatisticsDto
} from "../../page/arbitrage/modules/arbitrage-tenant/modules/arbitrage-project/modules/arbitrage-project-dashboard/models/arbitrage-project.models";

@Injectable()
export class ArbitrageApiService {

    constructor(private http: BaseService) {
    }

    getAllArbTenants(): Observable<ArbitrageTenantsInterface[]> {
        return this.http.get('arbitrage/tenants');
    }

    getAllArbProjects(
        tenantId: number,
        filterParams: ArbProjectsStatusEnum[] = [ArbProjectsStatusEnum.started, ArbProjectsStatusEnum.stopped, ArbProjectsStatusEnum.archived]
    ): Observable<ArbProjectsInterface[]> {
        let params = new HttpParams();
        params = params.append('status', filterParams.join(','));

        return this.http.get(`arbitrage/tenants/${tenantId}/projects`, { params });
    }

    getArbProjectsBalances(tenantId: number, list: ArbProjectsInterface[]): Observable<ArbProjectMarketBalancesInterface[]> {
        return this.http.get(`arbitrage/tenants/${tenantId}/projects/balances`).pipe(
        );
    }

    getProjectBalances(tenantId: number, projectId: number): Observable<ArbProjectMarketBalancesInterface> {
        return this.http.get(`arbitrage/tenants/${tenantId}/projects/${projectId}/balances`);
    }

    getArbProjectsTradesData(tenantId: number, list: ArbProjectsInterface[], dates: string[]): Observable<ArbProjectHistoryInterface[]> {
        const params = {from: dates[0], to: dates[1]};
        // return this.http.get(`arbitrage/tenants/${tenantId}/projects/tradeData`, {params});
        return of([
            {
                id: 3,
                type: 'sell', // sell/
                status: 'success', // succ
                base: 'DOGE',
                marketName: CexNamesEnum.kucoin,
                basePrice: 100,
                basePriceUsd: 150,
                baseVolume: 1000,
                baseVolumeUsd: 1100,
                fixingVolume: 2200,
                gasPrice: 1000,
                gasPriceUsd: 15,
                baseTradingFee: 5,
                fixingTradingFee: 6,
                tradingFeeUsd: 4,
                baseRebalanceFee: 5,
                fixingRebalanceFee: 5,
                rebalanceFeeUsd: 1,
                profitUsd: 200,
            },
            {
                id: 4,
                type: 'buy', // sell/
                status: 'success', // succ
                base: 'DOGE',
                marketName: CexNamesEnum.kucoin,
                basePrice: 100,
                basePriceUsd: 150,
                baseVolume: 1000,
                baseVolumeUsd: 1100,
                fixingVolume: 2200,
                gasPrice: 1000,
                gasPriceUsd: 15,
                baseTradingFee: 5,
                fixingTradingFee: 6,
                tradingFeeUsd: 4,
                baseRebalanceFee: 5,
                fixingRebalanceFee: 5,
                rebalanceFeeUsd: 1,
                profitUsd: 200,
            },
            {
                id: 6,
                type: 'sell', // sell/
                status: 'success', // succ
                base: 'DOGE',
                marketName: CexNamesEnum.kucoin,
                basePrice: 100,
                basePriceUsd: 150,
                gasPrice: 1000,
                baseVolume: 1000,
                baseVolumeUsd: 1100,
                fixingVolume: 2200,
                gasPriceUsd: 15,
                baseTradingFee: 5,
                fixingTradingFee: 6,
                tradingFeeUsd: 4,
                baseRebalanceFee: 5,
                fixingRebalanceFee: 5,
                rebalanceFeeUsd: 1,
                profitUsd: 200,
            }
        ]);
    }

    createProject(tenantId: number, payload: RoutesPayload): Observable<number> {
        return this.http.create(`arbitrage/tenants/${tenantId}/projects`, payload);
    }

    getArbProjectBalance(tenantId: number, projectId: number): Observable<ArbProjectBalancesDeploy> {
        return this.http.get(`arbitrage/tenants/${tenantId}/projects/${projectId}/wizardBalances`);
    }

    getArbProjectInfo(tenantId: number, id: number): Observable<RoutesCreateDto> {
        return this.http.get(`arbitrage/tenants/${tenantId}/projects/${id}/info`);
    }

    deployVaults(tenantId: number, id: number): Observable<void> {
        return this.http.create(`arbitrage/tenants/${tenantId}/projects/${id}/deployVaults`, {});
    }

    deployVaultsSatus(tenantId: number, id: number): Observable<ArbProjectVaultsDeploymentsDto> {
        return this.http.get(`arbitrage/tenants/${tenantId}/projects/${id}/deploymentDetails`);
    }

    getDexInfo(payload: WizardDexInfo): Observable<DexTickersInterface> {
        return this.http.get(`arbitrage/tokens/dexInfo`, {params: payload});
    }

    getCexInfo(payload: WizardCexInfo): Observable<string[]> {
        return this.http.get(`arbitrage/tokens/cexInfo`, {params: payload});
    }

    //TODO not for arbitrage!!!
    updateProject(tenantId: number, projectId: number, projectName: string): Observable<ArbProjectsInterface> {
        return this.http.update(`tenants/${tenantId}/Projects/${projectId}`, {
            name: projectName,
        });
    }

    updateArbProjectstatus(tenantId: number, projectId: number, status: ArbProjectsStatusEnum) {
        return this.http.create(`arbitrage/tenants/${tenantId}/projects/${projectId}/changeStatus`, {
            newStatus: status,
        })
    }

    updateProjectRoutes(tenantId: number, projectId: number, payload: RoutesPayloadPair[]) {
        return this.http.create(`arbitrage/tenants/${tenantId}/projects/${projectId}/routes`, payload)
    }
    //TODO not for arbitrage!!!
    deleteProject(tenantId: number, projectId: number) {
        return this.http.delete(`tenants/${tenantId}/Projects/${projectId}`);
    }
    //TODO not for arbitrage!!!
    getProjectActiveProfiles(tenantId: number, projectId: number): Observable<ProjectActiveProfilesInterface[]> {
        return this.http.get(`tenants/${tenantId}/Projects/${projectId}/activeProfiles`);
    }
    //TODO not for arbitrage!!!
    moveProfiles(tenantId: number, projectId: number, body: ProfileMoveInterface) {
        return this.http.update(`tenants/${tenantId}/Projects/${projectId}/moveProfiles`, body);
    }
    //TODO not for arbitrage!!!
    getArbProjectsSettings(tenantId: number, projectId: number): Observable<ProjectsSettingsDto> {
        return this.http.get(`tenants/${tenantId}/Projects/${projectId}/settings`);
    }
    //TODO not for arbitrage!!!
    updateArbProjectsSettings(
        tenantId: number,
        projectId: number,
        ArbProjectsSettings: ProjectsSettingsDto,
    ): Observable<ProjectsSettingsDto> {
        return this.http.update(`tenants/${tenantId}/Projects/${projectId}/settings`, {
            ...ArbProjectsSettings,
        });
    }
    //
    // getPoolFees(tenantId: number, base: string, quote: string, dex: DexNamesEnum) {
    //     const params = {tokenA: base.toLowerCase(), tokenB: quote.toLowerCase(), dex};
    //     return this.http.get(`arbitrage/tenants/${tenantId}/projects/getPoolFees`, {params})
    // }

    getStatistics(
        tenantId: number,
        projectId: number,
        dates: string[],
    ): Observable<GetStatisticsDto[]> {
        const params = {from: dates[0], to: dates[1]};
        return this.http.get(`arbitrage/tenants/${tenantId}/Projects/${projectId}/statistics`, {params});
    }
}
