import {Injectable} from '@angular/core';
import {ActivatedRoute, Params, QueryParamsHandling, Router} from "@angular/router";
import {AddProfileWizardStepsEnum, AddProfileWizardType} from "@shared/enums/add-profile-wizard-steps.enum";
import {VaultModesEnum} from "@shared/enums/vault-modes.enum";
import {ProfileType} from "@shared/interfaces/profiles.interface";

@Injectable()
export class NavigateService {
    tenantId: number;
    projectId: number;
    profileId: number;
    arbTenantId: number;
    arbProjectId: number;
    profileType: ProfileType;

    constructor(
        private readonly router: Router,
        private readonly route: ActivatedRoute,
    ) {
    }

    setTenantId(tenantId: number) {
        this.tenantId = tenantId;
    }

    setProjectId(projectId: number) {
        this.projectId = projectId;
    }

    setProfileId(profileId: number) {
        this.profileId = profileId;
    }

    setProfileType(profileType: ProfileType) {
        this.profileType = profileType;
    }

    setArbTenantId(arbTenantId: number) {
        this.arbTenantId = arbTenantId;
    }

    setArbProjectId(arbProjectId: number) {
        this.arbProjectId = arbProjectId;
    }

    navigateToTenants(): void {
        this.router.navigate(['/tenants']);
    }

    navigateToTenant(tenantId: number = this.tenantId): void {
        this.router.navigate([tenantId ? `/tenants/${tenantId}` : '/tenants']);
    }

    navigateToSelectAddType(
        projectId: number = this.projectId,
        tenantId: number = this.tenantId
    ) {
        this.router.navigate([tenantId && projectId ? `/tenants/${tenantId}/projects/${projectId}/profiles/add` : '/tenants']);
    }

    navigateToProject(
        projectId: number = this.projectId,
        tenantId: number = this.tenantId
    ): void {
        this.router.navigate([tenantId && projectId ? `/tenants/${tenantId}/projects/${projectId}` : '/tenants']);
    }

    navigateToProfile(
        profileId: number = this.profileId,
        profileType?: ProfileType,
    ): void {
        this.router.navigate([
            this.tenantId && this.projectId && profileId ?
                `/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${profileId}` : '/tenants'], {queryParams: {profileType}});
    }

    navigateToCexProfile(
        profileId: number = this.profileId,
    ): void {
        this.router.navigate([
            this.tenantId && this.projectId && profileId ?
                `/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${profileId}/cex` : '/tenants'], {queryParams: {profileType: ProfileType.Cex}});
    }

    navigateToProfileEdit(profileId: number = this.profileId): void {
        this.router.navigate([`/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${profileId}/edit`]);
    }

    navigateToStrategies(profileId: number = this.profileId): void {
        this.router.navigate([`/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${profileId}/strategies`]);
    }

    navigateToCexStrategies(profileId: number = this.profileId): void {
        this.router.navigate([`/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${profileId}/cex/strategies`], {
            queryParamsHandling: 'merge'
        });
    }

    navigateToStrategiesEdit(strategyId: number): void {
        this.router.navigate([
            `/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${this.profileId}/ranges/${strategyId}/edit`
        ], {queryParamsHandling: 'merge'});
    }

    navigateToStrategiesAdd(state?): void {
        this.router.navigate([
            `/tenants/${this.tenantId}/projects/${this.projectId}/profiles/${this.profileId}/ranges/add`
        ], {state, queryParamsHandling: 'merge'});
    }

    //  Wizard  ----------------------------------//

    navigateProfileWizard(step: AddProfileWizardStepsEnum, type: AddProfileWizardType = AddProfileWizardType.dex, profile?: number): void {
        this.router.navigate([
            this.tenantId && this.projectId ? profile ?
                `/tenants/${this.tenantId}/projects/${this.projectId}/profiles/add/${type}/step/${step}/${profile}` :
                `/tenants/${this.tenantId}/projects/${this.projectId}/profiles/add/${type}/step/${step}` :
                '/tenants']);
    }


    //  Arbitrage  ----------------------------------//

    navigateToArbTenants(): void {
        this.router.navigate(['/arbitrage/tenants']);
    }

    navigateToArbTenant(arbTenantId: number = this.arbTenantId): void {
        this.router.navigate([arbTenantId ? `/arbitrage/tenants/${arbTenantId}` : '/arbitrage/tenants']);
    }

    navigateToArbProject(
        arbProjectId: number = this.arbProjectId,
        arbTenantId: number = this.arbTenantId,
        ): void {
        this.router.navigate([
            arbTenantId && arbProjectId ?
                `/arbitrage/tenants/${arbTenantId}/projects/${arbProjectId}` : '/arbitrage/tenants']);
    }

    navigateToArbProjectCreate(): void {
        this.router.navigate([`/arbitrage/tenants/${this.arbTenantId}/project-create`]);
    }

    navigateToArbProjectCreateRoutes(id: number | string = ''): void {
        this.router.navigate([`/arbitrage/tenants/${this.arbTenantId}/project-create/routes/${id}`]);
    }

    navigateToArbProjectCreateDeploy(id: number | string | null = ''): void {
        this.router.navigate([`/arbitrage/tenants/${this.arbTenantId}/project-create/deploy/${id}`]);
    }

    // View Profile Dashboard -------------------//

    navigateToProfileView(id: number, profileType: ProfileType = this.profileType): void {
        this.router.navigate([`dashboard-view/${id}`], {queryParams: {profileType}});
    }

    //  Errors  ----------------------------------//

    navigateToForbidden(): void {
        this.router.navigate(['error/403']);
    }

    navigateToNotFound(): void {
        this.router.navigate(['error/404']);
    }

    navigateToServerError(): void {
        this.router.navigate(['error/500'])
    }

    // Navigate with query -----------------------//

    setQueryParams(
        params: Params,
        blockScroll = false,
        handling: QueryParamsHandling = 'merge',
        replaceUrl = true,
    ): void {
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: handling,
            state: blockScroll ? {blockScroll} : {},
            replaceUrl,
        });
    }



}
