<div class="samples">
    <div class="samples-item">
        <app-icons [name]="'peanut'"></app-icons>
        <span>peanut</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'graph'"></app-icons>
        <span>graph</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'info-circle'"></app-icons>
        <span>info-circle</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'alert'"></app-icons>
        <span>alert</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'arbitrage'"></app-icons>
        <span>arbitrage</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'batch-copy'"></app-icons>
        <span>batch-copy</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'shield'"></app-icons>
        <span>shield</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'rectangle'"></app-icons>
        <span>rectangle</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'clock-dashed'"></app-icons>
        <span>clock-dashed</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'file'" ></app-icons>
        <span>file</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'delete'"></app-icons>
        <span>delete</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'turn-on-off'"></app-icons>
        <span>turn-on-off</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'general-data'"></app-icons>
        <span>general-data</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'info-shield'"></app-icons>
        <span>info-shield</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'logout'"></app-icons>
        <span>logout</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'refresh'"></app-icons>
        <span>refresh</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'reload'"></app-icons>
        <span>reload</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'settings'"></app-icons>
        <span>settings</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'suitcase'"></app-icons>
        <span>suitcase</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'wallet'"></app-icons>
        <span>wallet</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'memory'"></app-icons>
        <span>memory</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'farm'"></app-icons>
        <span>farm</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'security'"></app-icons>
        <span>security</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'add'"></app-icons>
        <span>add</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'remove'"></app-icons>
        <span>remove</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'check'"></app-icons>
        <span>check</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'multihop'"></app-icons>
        <span>multihop</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'cex'"></app-icons>
        <span>cex</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'sort'"></app-icons>
        <span>sort</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'success-sort'"></app-icons>
        <span>success-sort</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'cancel-sort'"></app-icons>
        <span>cancel-sort</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'share'"></app-icons>
        <span>share</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'withdraw'" height="24" width="24"></app-icons>
        <span>withdraw</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'send'" height="24" width="24"></app-icons>
        <span>send</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'archive'" height="24" width="24"></app-icons>
        <span>archive</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'colors'" height="24" width="24"></app-icons>
        <span>colors</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'assign'" height="24" width="24"></app-icons>
        <span>assign</span>
    </div>
    <div class="samples-item">
        <app-icons [name]="'anchor-wallet'" height="24" width="24"></app-icons>
        <span>anchor-wallet</span>
    </div>
</div>
