export enum AddProfileWizardStepsEnum {
    chooseProfile = 100,
    createProfile = 200,
    ethAddressFill = 300,
    vaultDeployment = 400,
    vaultFill = 500,
    final = 900,
}

export enum AddProfileWizardType {
    dex = 'dex',
    cex = 'cex'
}
