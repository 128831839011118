import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    template: ''
})
export class BasicComponent implements OnDestroy {
    destroyer = new Subject();

    ngOnDestroy() {
        this.destroyer.next(true);
        this.destroyer.unsubscribe();
    }
}
