export const blast = {
    id: 81457,
    name: "Blast",
    network: "blast",
    nativeCurrency: {
        name: "Ether",
        symbol: "ETH",
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http:  ["https://blast.blockpi.network/v1/rpc/public"],
        },
        public: {
            http:  ["https://blast.blockpi.network/v1/rpc/public"],
        },
    },
    blockExplorers: {
        etherscan: {
            name: "Blastscan",
            url: "https://blastscan.io/",
        },
        default: {
            name: "Blastscan",
            url: "https://blastscan.io/",
        },
    },
}

export const saakuru = {
    id: 7225878,
    name: 'Saakuru Mainnet',
    network: 'saakuru',
    symbol: 'OAS',
    nativeCurrency: {
        name: "Oasys",
        symbol: "OAS",
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http:  ["https://rpc.saakuru.network"],
        },
        public: {
            http:  ["https://rpc.saakuru.network"],
        },
    },
    blockExplorers: {
        default: {
            name: "Saakuru",
            url: "https://explorer.saakuru.network/",
        },
    },
}

export const vtru = {
    id: 1490,
    name: 'Vitruveo MainNet RC1',
    network: 'vtru',
    symbol: 'VTRU',
    nativeCurrency: {
        name: "VTRU",
        symbol: "VTRU",
        decimals: 18,
    },
    rpcUrls: {
        default: {
            http:  ["https://rpc.vitruveo.xyz/"],
        },
        public: {
            http:  ["https://rpc.vitruveo.xyz/"],
        },
    },
    blockExplorers: {
        default: {
            name: "VTRU",
            url: "https://explorer.vitruveo.xyz/",
        },
    },
}
