import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {BasePermission} from "@shared/directives/permissions/base-permission";
import {PermissionService} from "@core/permission.service";
import {PermissionsEnum} from "@shared/enums/permissions.enum";

@Directive({ selector: '[appShowPermissionsFor]' })
export class ShowPermissionsForDirective extends BasePermission {

    showHasPermission: boolean;

    @Input() set appShowPermissionsFor(input: PermissionsEnum) {
        this.showHasPermission = this.checkPermissions(input);
    }

    @Input() appShowPermissionsForElse?: TemplateRef<unknown>;

    constructor(
        templateRef: TemplateRef<any>,
        viewContainer: ViewContainerRef,
        permissionService: PermissionService,
    ) {
        super(templateRef, viewContainer, permissionService);
    }

    ngOnChanges() {
        this.toggleVisibility(this.showHasPermission, this.appShowPermissionsForElse);
    }
}
